import React, { useState } from "react";
import { UtilityFunctions } from "../utils/UtilityFunctions";
import Slider from "../components/slider";
import LegalModal from "../components/legalModal";
import { Link } from "react-router-dom";
import SignInUp from "../components/signInUpModal";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { userService } from "../_services/user.service";
import { cookieService } from "_services";
import { toast } from "react-toastify";
import ToastHeader from "components/toastHeader";
import SuccessToast from "components/successToast";
import ValidationErrorToast from "components/validationErrorToast";
import MainHoc from "./../components/hoc";
import Loader from "common/Loader";

const SignUp = (props) => {
  const emailSignUpPlaceholder = STRING_CONSTANTS.LOGIN_PAGE_STRING.SIGNUPEMAIL;
  const passwordSignUpPlaceholder =
    STRING_CONSTANTS.LOGIN_PAGE_STRING.SIGNUPPASSWORD;
  const [emailSignUp, setemailSignUp] = useState("");
  const [passwordSignUp, setpasswordSignUp] = useState("");
  const [btnClick, setbtnClick] = useState(false);
  const [enableButton, setEnableButton] = useState(true);
  const [activeTab, setActiveTab] = useState("terms");
  const [loading, setLoading] = useState(false);

  const handleClick = (e) => {
    setbtnClick(true);
    e.preventDefault();

    // cookieService.removeAllCookies();

    const { history } = props;

    let params = {
      email: emailSignUp,
      password: passwordSignUp,
      password2: passwordSignUp,
      first_name: "",
      last_name: "",
      // profile_image: '',
      mobile_number: "",
      is_clinician: true, //for clinician Registration
    };
    if (UtilityFunctions.isEmptyOrNullOrUndefined(emailSignUp)) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_toast_email,
        STRING_CONSTANTS.validationString.req_email
      );
    } else if (!UtilityFunctions.validateEmail(emailSignUp)) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_toast_email,
        STRING_CONSTANTS.validationString.req_email_incorrect
      );
    } else if (UtilityFunctions.isEmptyOrNullOrUndefined(passwordSignUp)) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_password,
        STRING_CONSTANTS.validationString.req_password
      );
    } else if (UtilityFunctions.validatePassword(passwordSignUp)) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_password,
        STRING_CONSTANTS.validationString.req_password_incorrect
      );
    } else {
      if (!emailSignUp && !passwordSignUp) {
        return;
      } else {
        if (enableButton) {
          setEnableButton(false);
          setLoading(true);
          userService
            .signup(params)
            .then((res) => {
              SuccessToast();
              setEnableButton(true);
              cookieService.saveCookie(
                STRING_CONSTANTS.cookieStrings.token,
                res.data.access
              );
              history.push("/Subscription");
              setLoading(false);
            })
            .catch((err) => {
              setEnableButton(true);
              ToastHeader(err);
              setLoading(false);
              return;
            });
        }
      }
    }
  };
  return (
    <MainHoc>
      {loading && <Loader />}
      <div className="col-md-6 left-panel">
        <div className="logo-wrap">
          <img src={UtilityFunctions.getImageURL() + "logo.svg"} alt="" />
        </div>
        <div className="left-wrap w-100">
          <h4>Time to Get Started.</h4>
          <h2>
            <span className="fw-normal">Welcome to </span>
            <span className="primary-blue fw-bold">Mind</span>{" "}
            <span className="purple fw-bold">Geni!</span>
          </h2>
          <h5>
            Already a Member?{" "}
            <Link className="light-blue fw-bold" to={"/signin"}>
              Log In
            </Link>
          </h5>
          <SignInUp
            ePlaceholder={emailSignUpPlaceholder}
            pPlaceholder={passwordSignUpPlaceholder}
            email={emailSignUp}
            setemail={setemailSignUp}
            password={passwordSignUp}
            setpassword={setpasswordSignUp}
            btnClick={btnClick}
            setbtnClick={setbtnClick}
          />
          <p className="mt-5 mb-5">
            By continuing you agree to our
            <span
              className="text-white pointer"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => setActiveTab("terms")}
            >
              {" "}
              Terms{" "}
            </span>
            <span className="text-white">&</span>
            <span
              className="text-white pointer"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => setActiveTab("privacy")}
            >
              {" "}
              Privacy{" "}
            </span>
          </p>
          <LegalModal activeTabState={activeTab} />
          <div className="d-grid text-center">
            <button className="btn btn-cta" onClick={handleClick}>
              {" "}
              Join Mind Geni
            </button>
          </div>
        </div>
      </div>
      <Slider />
    </MainHoc>
  );
};

export default SignUp;
