import React, { useState } from 'react';
import { UtilityFunctions } from '../utils/UtilityFunctions';
import Slider from '../components/slider';
import { Link } from 'react-router-dom';
import { userService } from '../_services/user.service';
import { toNamespacedPath } from 'node:path/win32';
import { toast } from 'react-toastify';
import ToastHeader from 'components/toastHeader';
import SuccessToast from 'components/successToast';
import { STRING_CONSTANTS } from 'utils/constants/stringConstants';
import MainHoc from './../components/hoc';
import ValidationErrorToast from 'components/validationErrorToast';
class ForgotPassword extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            disable: true,
            resetemail: props && props.location && props.location.state && props.location.state.email || "",
            btnClick: false,
            enableButton: true
        }
    }


    render() {

        const store = !UtilityFunctions.validateEmail(this.state.resetemail);
        const handleReset = () => {
            this.setState({ btnClick: true });
            const params = {
                email: this.state.resetemail
            }
            if (UtilityFunctions.isEmptyOrNullOrUndefined(this.state.resetemail)) {
                ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_toast_email, STRING_CONSTANTS.validationString.req_email);
            }
            else if (!UtilityFunctions.validateEmail(this.state.resetemail)) {
                ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_toast_email, STRING_CONSTANTS.validationString.req_email_incorrect);
            }
            else{
            if (this.state.enableButton) {
                this.setState({ enableButton: false });
                userService.forgotPassword(params)
                    .then((res) => {
                        this.setState({ enableButton: true });
                        SuccessToast();
                        this.props.history.push('/signin');
                    })
                    .catch((err) => {
                        this.setState({ enableButton: true });
                        ToastHeader(err);
                    });
            }}
        }
        return (
            <MainHoc>
                <div className="col-md-6 left-panel">
                    <div className="logo-wrap">
                        <img src={UtilityFunctions.getImageURL() + 'logo.svg'} alt="" />
                    </div>
                    <div className="left-wrap w-100">
                        <h4 className='pointer' onClick={() => this.props.history.goBack()}><img src={UtilityFunctions.getImageURL() + 'arrow-left.svg'} alt="left Arrow" /> Forgot Password</h4>
                        <h2>Forgot Password? <br />
                            Reset below...</h2>
                        <h5>Reset your password below by adding in your email ID.</h5>

                        <div className="d-grid">
                            <div
                                className={`form-floating-custom ${!this.state.btnClick ? "" : store === false ? "" : "red-bg"}`}
                            >
                                <input type="email" className={`form-floating-custom ${!this.state.btnClick ? "" : store === false ? "" : "is-invalid"}`} value={this.state.resetemail} placeholder="Enter your Email Address" onChange={(e) => { this.setState({ resetemail: e.target.value }); this.setState({ btnClick: false }); }} />
                                <label>Email</label>
                            </div>
                            {!this.state.btnClick ? "" : store === false ? "" : this.state.resetemail.length > 0 ? <div className='display-error-message'><img alt="" className="toast-icon" src={UtilityFunctions.getImageURL() + "error-message-icon.svg"} /><span className='error-message-field'>{STRING_CONSTANTS.validationString.req_email_incorrect}</span></div> : <div className='display-error-message'><img alt="" className="toast-icon" src={UtilityFunctions.getImageURL() + "error-message-icon.svg"} /><span className='error-message-field'>{STRING_CONSTANTS.validationString.req_email}</span></div>}
                            <div className='mb-4'></div>
                        </div>

                        <h5 className='mb-5 mt-3'>Not a Member? <Link to={'/signup'} className='light-blue fw-bold'>Sign Up</Link></h5>

                        <div className="d-grid text-center">
                            <button className='btn btn-cta' onClick={handleReset}> Reset Password</button>
                        </div>
                    </div>
                </div>
                <Slider />
            </MainHoc>
        );
    }
}

export default ForgotPassword;
