import React, { useEffect, useState } from "react";
import { UtilityFunctions } from "utils/UtilityFunctions";

import InputMask from "react-input-mask";
import ImageCrop from "common/ImageCrop";

const BusinessDetails = (props) => {
  const [preview, setPreview] = useState(null);
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };

  return (
    <div className="admin-wrap">
      <div className="justify-content-center details-container">
        <div className="d-grid position-relative">
          <div className="image-wrapper m-auto">
            <div className="image-upload">
              {/* <label >
                                                            <label className="editButton" htmlFor="upload-bussiness-button">
                                                                <img src={UtilityFunctions.getImageURL() + 'edit-pencil.svg'} alt="" />
                                                            </label>
                                                            {props.bussinessLogo === null ? <img alt="" className="image-show" src={UtilityFunctions.getImageURL() + 'bussiness-logo.svg'} /> : props.showBussinessLogo === null ? <img className="image-show" src={props.bussinessLogo} /> : <img className="image-show" src={props.showBussinessLogo} />}
                                                        </label>
                                                        <input type="file" disabled={!props.checkAccess} onChange={(e) => props.bussinessImageUploadCheck(e)} id="upload-bussiness-button" style={{ display: 'none' }} /> */}
              <label className="editButton" htmlFor="image_id">
                <img
                  src={UtilityFunctions.getImageURL() + "edit-pencil.svg"}
                  alt=""
                />
              </label>
              <img
                alt=""
                className="image-show"
                src={
                  props.bussinessLogo == null
                    ? UtilityFunctions.getImageURL() + "logo.svg"
                    : preview != null
                    ? preview
                    : props.firstTimeLogoUploaded
                    ? URL.createObjectURL(props.bussinessLogo)
                    : props.bussinessLogo
                }
              />

              <ImageCrop
                ratio={16 / 16}
                croppedImage={(imgSrc, file) => {
                  //setSelectedFile(file)
                  setPreview(imgSrc);
                  props.setImgBusinessUploaded(true);
                  props.setBussinessLogo(file);
                  props.setFirstTimeLogoUploaded(true);
                  props.setImgBusinessUploaded(true);
                }}
                size={15}
              />
            </div>
          </div>
          {/* <div className="form-floating-custom-profile mb-4 pb-1" style={{ marginTop: 70 }}>
                                                    <input type="text" className='' placeholder="Enter ABN Number" value={abnNumber} onChange={(e) => setAbnNumber(e.target.value)} disabled={!checkAccess} />
                                                    <label>ABN Number</label>
                                                </div> */}
          <div className="v-all-form">
            <div className="v-mid-form">
              <div
                // className="form-floating-custom-profile mb-4 pb-1"
                className={`form-floating-custom-profile mb-4 pb-1 ${
                    !props.btnClick
                      ? ""
                      : UtilityFunctions.validateAbnNumber(props.abnNumber)
                      ? ""
                      : "form-floating-custom-profile mb-4 pb-1 red-bg red-bg-xs"
                  }`}
                style={{ marginTop: 70 }}
              >
                <InputMask
                  className={`form-floating-custom ${
                    !props.btnClick
                      ? ""
                      : UtilityFunctions.validateAbnNumber(props.abnNumber)
                      ? ""
                      : "is-invalid-contact is-invalid"
                  }`}
                  mask="99 999 999 999"
                  maskChar={null}
                  value={props.abnNumber}
                  placeholder="00 000 000 000"
                  onChange={(e) => {
                    props.setAbnNumber(e.target.value);
                    props.setBtnClick(false);
                  }}
                  // onChange={(e) => { props.setabnNumber(e.target.value); props.setbtnClick(false) }}
                ></InputMask>
                <label>ABN Number</label>
              </div>

              <div 
              className="form-floating-custom-profile mb-4 pb-1"
              
              >
                <div
                     className={
                      !props.btnClick
                        ? "wrap mobile-number-wrap"
                        : UtilityFunctions.validatePhone(props.mobileNumber)
                        ? "wrap mobile-number-wrap"
                        : "wrap mobile-number-wrap red-bg red-bg-xs"
                    }
                >
                  <InputMask
                    className={
                      !props.btnClick
                        ? "mob-input"
                        : UtilityFunctions.validatePhone(props.mobileNumber)
                        ? "mob-input"
                        : "mob-input is-invalid-contact is-invalid"
                    }
                    mask="+61  999 999 999 9"
                    maskChar={null}
                    value={props.mobileNumber}
                    placeholder="+61  000 000 000"
                    onChange={(e) => {
                      props.setMobileNumber(e.target.value);
                      props.setBtnClick(false);
                    }}
                  ></InputMask>
                  <span className=""></span>
                  <label className="mob-label">
                    <span>Mobile Number</span>
                  </label>
                </div>
              </div>

              <div 
            //   className=""
              className={
                    props.address.length > 0
                      ? "form-floating-custom-profile mb-4 pb-1"
                      : "form-floating-custom-profile mb-4 pb-1 red-bg red-bg-xs"
                  }
              >
                <input
                  type="text"
                  className={
                    props.address.length > 0
                      ? ""
                      : "is-invalid-contact is-invalid"
                  }
                  placeholder="Enter Address Line 1"
                  value={props.address}
                  onChange={(e) => props.setAddress(e.target.value)}
                  disabled={!props.checkAccess}
                />
                <label>Address Line 1</label>
              </div>
              <div 
            //   className=""
              className={
                    props.city.length > 0 ? "form-floating-custom-profile mb-4 pb-1" : "form-floating-custom-profile mb-4 pb-1 red-bg red-bg-xs"
                  }
              >
                <input
                  type="text"
                  className={
                    props.city.length > 0 ? "" : "is-invalid-contact is-invalid"
                  }
                  placeholder="Enter City/Suburb"
                  value={props.city}
                  onChange={(e) => props.setCity(e.target.value)}
                  disabled={!props.checkAccess}
                />
                <label>City/Suburb</label>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  <div className="form-floating-custom-profile mb-4 pb-1">
                    <input
                      type="text"
                      placeholder="State"
                      value={props.addState}
                      onChange={(e) => props.setAddState(e.target.value)}
                      disabled={true}
                    />
                    <label>Enter State</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating-custom-profile mb-4 pb-1">
                    <input
                      type="text"
                      placeholder="Add Post Code"
                      value={props.code}
                      onChange={(e) => props.setCode(e.target.value)}
                      disabled={true}
                    />
                    <label>Post Code</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-grid text-center">
              <button
                className="btn btn-cta btn-color"
                onClick={props.updateBussinessDetails}
                disabled={!props.checkAccess}
              >
                {" "}
                Save Changes{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BusinessDetails;
