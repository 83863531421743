import React, { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { UtilityFunctions } from "utils/UtilityFunctions";
import { STRING_CONSTANTS } from "utils/constants/stringConstants";
import InputMask from "react-input-mask";
import ImageCrop from "common/ImageCrop";
import Welcome from "./profile/welcome";
import SuccessToast from "components/successToast";
import { userService } from "_services";
import ToastHeader from "components/toastHeader";
import ValidationErrorToast from "components/validationErrorToast";
import Loader from "common/Loader";
import WebcamCapture from "./webcamCapture";

const Step2 = (props) => {
  const [progress, setProgress] = useState(66);
  const [abnNumber, setabnNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [btnClick, setbtnClick] = useState(false);
  const [addState, setAddState] = useState("");
  const [code, setCode] = useState("");
  const [clinicName, setClinicName] = useState("");
  const [enableButton2, setEnableButton2] = useState(true);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState(null);
  const [businessName, setBusinessName] = useState("");
  const [imgUploaded, setImgUploaded] = useState(false);
  const [captureType, setCaptureType] = useState("");
  const [onUpload, setOnUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const webcamRef = React.useRef(null);
  const [CaptureImgSrc, setCaptureImgSrc] = React.useState(null);
  const [permissionText, setPermissionText] = React.useState(null);
  const imageHandler = {
    removeFile: () => {
      setSelectedFile(null);
      setPreview(null);
      setCaptureImgSrc(null);
    },
  };
  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };
  const closePopup = () => {
     setTimeout(() => {
      setOnUpload(false);
    }, 200);
  };
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCaptureImgSrc(imageSrc);
    setCaptureType("");
    const imgFile: any = dataURLtoFile(imageSrc, "profile_img.png");
    setPreview(null);
    setSelectedFile(imgFile);
    closePopup();
    setImgUploaded(false);
  }, [webcamRef, setCaptureImgSrc]);

  useEffect(() => {
    setLoading(true);
    userService
      .viewProfile()
      .then((response) => {
        if (response) {
          setPreview(
            response.clinic_details.clinic_logo ? response.clinic_details.clinic_logo : ""
          );
          setMobileNumber(
            response.user.mobile_number ? response.user.mobile_number : ""
          );
          setabnNumber(
            response.clinic_details.clinic_abn
              ? response.clinic_details.clinic_abn
              : ""
          );
          setImgUploaded(true);
        }
      })
      .catch((err) => {
        ToastHeader(err);
      });
    setLoading(false);
  }, []);

  const updateStep2 = () => {
    setbtnClick(true);
    if (UtilityFunctions.isEmptyOrNullOrUndefined(abnNumber)) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_abn_number,
        STRING_CONSTANTS.validationString.req_abn_number
      );
    } else if (!UtilityFunctions.validateAbnNumber(abnNumber)) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_abn_number,
        STRING_CONSTANTS.validationString.req_abn_number_incorrect
      );
    } else if (UtilityFunctions.isEmptyOrNullOrUndefined(mobileNumber)) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_number,
        STRING_CONSTANTS.validationString.req_number
      );
    } else if (
      UtilityFunctions.trimPhoneNumberSpaces(mobileNumber).length < 12
    ) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_number,
        STRING_CONSTANTS.validationString.req_number_incorrect
      );
    } else if (
      UtilityFunctions.isEmptyOrNullOrUndefined(selectedFile) &&
      !imgUploaded
    ) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_bussiness_logo,
        STRING_CONSTANTS.validationString.req_bussiness_logo
      );
    } else {
      const formData = new FormData();

      if (!imgUploaded && selectedFile) {
        formData.append("clinic_logo", selectedFile);
      }
      formData.append("clinic_abn", abnNumber);
      formData.append(
        "mobile_number",
        UtilityFunctions.trimPhoneNumberSpaces(mobileNumber)
      );
      const abnParams = {
        abn: abnNumber,
      };

      if (
        abnNumber !== "" &&
        mobileNumber !== "" &&
        selectedFile !== null &&
        enableButton2
      ) {
        setEnableButton2(false);
        userService
          .abnNumberLookUp(abnParams)
          .then((response) => {
            if (response.data.data.AbnStatus === "Cancelled") {
              ValidationErrorToast(
                STRING_CONSTANTS.formLabelString.label_abn_number,
                STRING_CONSTANTS.validationString.req_abn_number_inactive
              );
            } else if (response.data.data.AbnStatus === "") {
              ValidationErrorToast(
                STRING_CONSTANTS.formLabelString.label_abn_number,
                response.data.data.Message
              );
            } else {
              let a = response.data.data.AddressPostcode;
              let b = response.data.data.AddressState;
              let c = response.data.data.EntityName;
              let d =
                response.data.data.BusinessName.length === 0
                  ? ""
                  : response.data.data.BusinessName[0];
              setCode(a);
              setAddState(b);
              setClinicName(c);
              setBusinessName(d);
              let e = new FormData();
              e.append("clinic_name",response.data.data.EntityName);
              e.append("clinic_abn",abnNumber);
              e.append("clinic_logo", selectedFile);
            //   userService
            //     .clinicRegister(
            //         {
            //       clinic_name: response.data.data.EntityName,
            //       clinic_abn: abnNumber,
            //     })
            //     .then((response) => {
            //       formData.append("clinic", response.data.data.id);
            //       formData.append("web_step", "4");

                  //setClinicId(response.data.data.id);
                  formData.append("clinic_name", response.data.data.EntityName);
                  userService
                    .updateProfile(formData)
                    .then((res) => {
                      setbtnClick(false);

                      props.history.push({
                        pathname: `/profileStep3`,
                        state: {
                          businessName: d,
                          code: a,
                          addState: b,
                          clinicName: c,
                          abnNumber: abnNumber,
                          logo:selectedFile
                        },
                      });
                      SuccessToast();
                    })
                    .catch((err) => {
                      ToastHeader(err);
                    });
                // })
                // .catch((err) => {
                //   ToastHeader(err);
                // });
            }
            setEnableButton2(true);
          })
          .catch((err) => {
            setEnableButton2(true);
            ToastHeader(err);
          });
        return;
      }
    }
  };
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div
        className={
          onUpload
            ? "modal fade show capture_modal"
            : "modal fade capture_modal"
        }
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{
          display: onUpload ? "block" : "",
          opacity: onUpload ? "initial" : "0",
        }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <span className="capture_close" onClick={() => { closePopup();  setCaptureType(null)}}>&times;</span>
            <div className="modal-body">
              {captureType == "capture" && (
                <>
                  {permissionText ? (
                    <span
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        height: "100%",
                        paddingLeft: "20px",
                        paddingTop: "25px",
                      }}
                    >
                      {" "}
                      {permissionText}{" "}
                    </span>
                  ) : (
                    <div className="webcam_modal">
                      <WebcamCapture
                        onUserMediaError={(err) => {
                          if (err) {
                            setPermissionText(
                              "We need camera permission in order to perform this action, Please allow permissions from site Settings."
                            );
                          }
                        }}
                        width={"100%"}
                        height={"100%"}
                        ref={webcamRef}
                      />
                      <button className="btn btn-cta" onClick={capture}>
                        {" "}
                        Capture Image
                      </button>
                    </div>
                  )}
                </>
              )}
              {!captureType && (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-secondary me-4"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setCaptureType("capture");
                      //   closePopup();
                    }}
                  >
                    Open Camera
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-info"
                    onClick={() => {
                      closePopup();
                    }}
                    data-bs-dismiss="modal"
                  >
                    <label htmlFor="image_id" style={{cursor:"pointer"}}>Select from device</label>
                  </button>
                </>
              )}
              {/* <div className="image_upload_option">
                    
                      <label
                        onClick={() => {
                          setCaptureType("capture");
                          closePopup();
                        }}
                      >
                        Open Camera
                      </label>
                      <label htmlFor="image_id" onClick={closePopup}>
                        Select from device
                      </label>
                    </div> */}
            </div>
           
          </div>
        </div>
      </div>
      {/* )} */}
      <div className="container-fluid p-0">
        <div className="row gx-0 bg-blue vh-100">
          <div className="col-sm-6 left-panel">
            <div className="logo-wrap">
              <img src={UtilityFunctions.getImageURL() + "logo.svg"} alt="" />
            </div>
            <div className="left-wrap w-100 create_profile">
              <h4 className="m-0">Create Profile</h4>

              <ProgressBar
                baseBgColor="rgb(255 255 255 / 12%)"
                bgColor="#FFF"
                height="4px"
                width="160px"
                margin="16px 0px 24px 0px"
                completed={progress}
                customLabel="&nbsp;"
              />

              <h2 className="heading-wrap">Lets Create your Profile.</h2>
              <h5 className="heading-wrap">Some Business Details...</h5>
              <div className="d-flex align-items-center">
                <div className="container-faq-wrap">
                  <label onClick={() => setOnUpload(true)}>
                    {selectedFile || preview || CaptureImgSrc ? (
                      <>
                        <img
                          alt=""
                          src={preview || CaptureImgSrc}
                          className="bussiness-logo"
                        />
                        &nbsp;{" "}
                        <span
                          onClick={(e) => {
                            imageHandler.removeFile();
                            setImgUploaded(false);
                          }}
                          className="pointer"
                        >
                          {" "}
                          Remove
                        </span>
                        <p className="tile invisible mt-3">
                          {" "}
                          Upload Business Logo{" "}
                        </p>
                      </>
                    ) : (
                      <>
                        <img
                          alt=""
                          className="bussiness-logo pointer"
                          src={
                            UtilityFunctions.getImageURL() + "upload-logo.png"
                          }
                        />
                        <p className="tile"> Upload Business Logo </p>
                      </>
                    )}
                  </label>
                  {/* {onUpload && !selectedFile && !preview && !CaptureImgSrc && (
                   
                  )} */}
                </div>

                <ImageCrop
                  // ratio={16 / 16}
                  croppedImage={(imgSrc, file) => {
                    setSelectedFile(file);
                    setPreview(imgSrc);
                    setImgUploaded(false);
                  }}
                  size={15}
                />
              </div>
              <div className="mb-3"></div>
              <div className="d-grid">
                <div
                  // className="form-floating-custom"
                  className={`form-floating-custom ${
                      !btnClick
                        ? ""
                        : UtilityFunctions.validateAbnNumber(abnNumber)
                        ? ""
                        : "form-floating-custom red-bg"
                    }`}
                >
                  <InputMask
                    className={`form-floating-custom ${
                      !btnClick
                        ? ""
                        : UtilityFunctions.validateAbnNumber(abnNumber)
                        ? ""
                        : "is-invalid"
                    }`}
                    mask="99 999 999 999"
                    maskChar={null}
                    value={abnNumber}
                    placeholder="00 000 000 000"
                    onChange={(e) => {
                      setabnNumber(e.target.value);
                      setbtnClick(false);
                    }}
                  ></InputMask>
                  <label>ABN Number</label>
                </div>
                {!btnClick ? (
                  ""
                ) : abnNumber.length > 0 ? (
                  ""
                ) : (
                  <div className="display-error-message">
                    <img
                      alt=""
                      className="toast-icon"
                      src={
                        UtilityFunctions.getImageURL() +
                        "error-message-icon.svg"
                      }
                    />
                    <span className="error-message-field">
                      {STRING_CONSTANTS.validationString.req_abn_number}
                    </span>
                  </div>
                )}
                <div className="mb-3"></div>
                <div className="form-floating-custom">
                  <div
                    // className="wrap mobile-number-wrap"
                    className={
                        !btnClick
                          ? "wrap mobile-number-wrap"
                          : UtilityFunctions.validatePhone(mobileNumber)
                          ? "wrap mobile-number-wrap"
                          : "wrap mobile-number-wrap red-bg"
                      }
                  >
                    <InputMask
                      className={
                        !btnClick
                          ? "mob-input"
                          : UtilityFunctions.validatePhone(mobileNumber)
                          ? "mob-input"
                          : "mob-input is-invalid"
                      }
                      mask="+61 999 999 999 9"
                      maskChar={null}
                      value={mobileNumber}
                      placeholder="+61  000 000 000"
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                        setbtnClick(false);
                      }}
                    ></InputMask>
                    <span className=""></span>
                    <label className="mob-label">
                      <span>Mobile Number</span>
                    </label>
                  </div>
                </div>
                {!btnClick ? (
                  ""
                ) : mobileNumber.length > 0 ? (
                  ""
                ) : (
                  <div className="display-error-message">
                    <img
                      alt=""
                      className="toast-icon"
                      src={
                        UtilityFunctions.getImageURL() +
                        "error-message-icon.svg"
                      }
                    />
                    <span className="error-message-field">
                      {STRING_CONSTANTS.validationString.req_number}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-grid text-center w-100">
              {
                <button
                  className="btn btn-cta  mt-4"
                  onClick={() => updateStep2()}
                >
                  Next Step
                </button>
              }
            </div>
          </div>
          <Welcome />
        </div>
      </div>
    </React.Fragment>
  );
};
export default Step2;
