import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { UtilityFunctions } from '../utils/UtilityFunctions';
import Slider from '../components/slider';
import { cookieService, userService } from '_services';
import { STRING_CONSTANTS } from 'utils/constants/stringConstants';
import ToastHeader from 'components/toastHeader';
import SuccessToast from 'components/successToast';
import ValidationErrorToast from 'components/validationErrorToast';
import Loader from 'common/Loader';

const ResetPassword = (props) => {
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [newPwd, setNewPwd] = useState('');
    const [confNewPwd, setConfNewPwd] = useState('');
    const [loading, setLoading] = useState(false);

    const id = useParams();
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const toggleConfirmPassword = () => {
        if (confirmPasswordType === "password") {
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }
    const ResetPwdButton = () => {
        if (UtilityFunctions.isEmptyOrNullOrUndefined(newPwd)){
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_password, STRING_CONSTANTS.validationString.req_password);
        }
        else if (UtilityFunctions.validatePassword(newPwd)) {
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_password, STRING_CONSTANTS.validationString.req_password_incorrect);
        }
        else if (UtilityFunctions.isEmptyOrNullOrUndefined(confNewPwd)){
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_password, STRING_CONSTANTS.validationString.req_password);
        }
        else if (UtilityFunctions.validatePassword(confNewPwd)) {
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_password, STRING_CONSTANTS.validationString.req_password_incorrect);
        }
        else if (newPwd !== confNewPwd) {
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_password, STRING_CONSTANTS.validationString.req_password_notsame);
        }
        else{
        if (newPwd === confNewPwd) {
            const pwdParams = {
                new_password: newPwd
            }
            const url = {
                uid: id['uid'],
                token: id['token']
            }
            setLoading(true);
            userService.resetPassword(url, pwdParams)
                .then(() => {
                    props.history.push('/signin');
                    setLoading(false);
                    SuccessToast();
                })
                .catch((err) => {
                    ToastHeader(err);
                    setLoading(false);
                })
        }}
    }

    return (
        <div className='container-fluid p-0'>
            {loading && <Loader />}
            <div className="row gx-0 bg-blue vh-100">
                <div className="col-md-6 left-panel">
                    <div className="logo-wrap">
                        <img src={UtilityFunctions.getImageURL() + 'logo.svg'} alt="" />
                    </div>
                    <div className="left-wrap w-100">
                        <h4 className='pointer'><img src={UtilityFunctions.getImageURL() + 'arrow-left.svg'} alt="left Arrow" onClick={() => props.history.push('/forgotPassword')} /> Reset Password</h4>
                        <h2>Enter your new password <br /> below...</h2>
                        <h5 className='mb-5'>Create your new password below</h5>

                        <div className="d-grid">
                            <div className="form-floating-custom mb-4">
                                <input type={passwordType} placeholder="Create your new password" value={newPwd} onChange={(e) => setNewPwd(e.target.value)} />
                                <label>Password</label>
                                <img onClick={togglePassword} src={UtilityFunctions.getImageURL() + `${passwordType === "password" ? "eye_close.svg" : "eye_open.svg"}`} alt="" />
                                {/* <i onClick={togglePassword} className={passwordType === "password" ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i> */}
                            </div>
                        </div>

                        <div className="d-grid">
                            <div className="form-floating-custom mb-4">
                                <input type={confirmPasswordType} placeholder="Confirm your password" value={confNewPwd} onChange={(e) => setConfNewPwd(e.target.value)} />
                                <label>Password</label>
                                <img onClick={toggleConfirmPassword} src={UtilityFunctions.getImageURL() + `${confirmPasswordType === "password" ? "eye_close.svg" : "eye_open.svg"}`} alt="" />
                                {/* <i onClick={togglePassword} className={passwordType === "password" ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i> */}
                            </div>
                        </div>

                        <h5 className='mb-5'>Not a Member? <Link className='light-blue fw-bold' to={'/signup'}>Sign Up</Link></h5>

                        <div className="d-grid text-center">
                            <button className='btn btn-cta' onClick={() => ResetPwdButton()}> Reset Password</button>
                        </div>
                    </div>
                </div>
                <Slider />
            </div>
        </div>
    );
}


export default ResetPassword;

