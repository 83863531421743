import React, { useEffect, useRef, useState } from 'react';
import { UtilityFunctions } from '../utils/UtilityFunctions';
import Switch from "react-switch";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import { userService } from "../_services";
import ToastHeader from '../components/toastHeader';
import Stripe from '../stripe';
import MainHoc from './../components/hoc';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SuccessToast from 'components/successToast';
import Loader from 'common/Loader';
const planImages = [{
    leftImage: 'leaf-g-left.svg',
    rightImage: 'leaf-g-right.svg'
},
{
    leftImage: 'leaf-y-left.svg',
    rightImage: 'leaf-y-right.svg'
},
{
    leftImage: 'leaf-r-left.svg',
    rightImage: 'leaf-r-right.svg'
},
{
    leftImage: 'leaf-r-left.svg',
    rightImage: 'leaf-r-right.svg'
}]
const Subscription = (props) => {
    const MONTH = "month";
    const YEAR = "year";
    const [monthlyData, setMonthlyData] = useState([]);
    const [yearlyData, setYearlyData] = useState([]);
    const [mappedData, setMappedData] = useState([]);
    const [selected, setSelected] = useState(null)
    const [checked, setChecked] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const scrollRef = useRef(null)

    const [selectedCardId, setSelectedCardId] = useState('');
    const [planType, setPlanType] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        userService.getProducts()
            .then((res) => {
                let month = res && res.data.filter((i) => i.interval === MONTH);
                setMappedData(month);
                setMonthlyData(month);
                setSelected(month[0])
                let year = res && res.data.filter((i) => i.interval === YEAR);
                setYearlyData(year);
                userService.selectedPlan()
                    .then((res) => {
                        setSelectedCardId(res.data.data[0].plan.id);
                        setPlanType((res.data.data[0].plan.interval).toUpperCase())
                    })
                    .catch((err) => {
                        //ToastHeader(err);
                    })
            })
            .catch(function (error) {
                ToastHeader(error);
            });
    }, [])
    useEffect(() => {
        if (selectedCardId && planType) {
            if (planType === 'MONTH') {
                monthlyData.map((item, i) => {
                    if (item.id === selectedCardId) {
                        scrollRef?.current?.slickGoTo(i)
                    }
                })
            }
            else {
                setChecked(true);
                setMappedData(yearlyData);
                yearlyData.map((item, i) => {
                    if (item.id === selectedCardId) {
                        scrollRef?.current?.slickGoTo(i)
                    }

                })
            }
        }
    }, [planType])

    const handleChange = (checked) => {
        if (checked) {
            setSelected(yearlyData[currentIndex]);
            setMappedData(yearlyData);
            setChecked(checked);
        }
        else {
            setSelected(monthlyData[currentIndex]);
            setMappedData(monthlyData);
            setChecked(checked);
        }
    }

    const options = {
        items: 1,
        //nav: true
        nav: true,
        //loop: true
        loop: false,
        rewind: true,
        margin: 32,
        center: true,
        autoplay: false,
        stagePadding: 100,
        navText: ["", ""],
        dots: false,
        responsive: {
            0: {
                items: 1,
                stagePadding: 0
            },
            576: {
                items: 1,
                stagePadding: 100
            },
            768: {
                items: 1,
                stagePadding: 0
            },
            1301: {
                items: 1,
                stagePadding: 0
            },
            1351: {
                items: 1,
                stagePadding: 60
            },
            1400: {
                items: 1,
                stagePadding: 100
            }
        }
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        // variableWidth: true,
        autoplay: false,
        centerMode: true,
        centerPadding: '220px',
        adaptiveHeight: true,

        responsive: [
            {
                breakpoint: 1900,
                settings: {
                    centerPadding: '220px',
                }
            },
            {
                breakpoint: 1800,
                settings: {
                    centerPadding: '200px',
                }
            },
            {
                breakpoint: 1700,
                settings: {
                    centerPadding: '170px',
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    centerPadding: '150px',
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    centerPadding: '120px',
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    centerPadding: '100px',
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    centerPadding: '80px',
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    centerPadding: '0px',
                }
            },
            {
                breakpoint: 992,
                settings: {
                    centerPadding: '0',
                }
            },
            {
                breakpoint: 767,
                settings: {
                    centerPadding: '220px',
                }
            },
            {
                breakpoint: 700,
                settings: {
                    centerPadding: '150px',
                }
            },
            {
                breakpoint: 600,
                settings: {
                    centerPadding: '0',
                }
            }
        ]
    };

    const eventsFun = {
        onDragged: function (event) {
            event.preventDefault();
            const Products_Name = mappedData && mappedData.map((i, ev) => {
                return { id: ev, value: i.nickname }
            })

            if (mappedData && mappedData.length > 0) {
                //var store = mappedData.filter((i) => i.nickname === (event && event.item && Products_Name[event.item.index] && Products_Name[event.item.index].value))[0];
                var store = mappedData.filter((i) => i.nickname === (event && event.page && Products_Name[event.page.index] && Products_Name[event.page.index].value))[0];
                if (store) {
                    setSelected(store);
                }
            }
        },
        onChanged: function (event) {
            event.preventDefault();
            // const Products_Name = mappedData && mappedData.map((i, ev) => {
            //     return { id: ev, value: i.nickname }
            // })

            // if (mappedData && mappedData.length > 0) {
            //     //var store = mappedData.filter((i) => i.nickname === (event && event.item && Products_Name[event.item.index] && Products_Name[event.item.index].value))[0];
            //     var store = mappedData.filter((i) => i.nickname === (event && event.page && Products_Name[event.page.index] && Products_Name[event.page.index].value))[0];
            //     if (store) {
            //         console.log('monthly selected' , store);
            //         setSelected(store);
            //     }

            // }


        }
    }
    let getPlan = () => {
        return selected;
    }
    const handleChange7 = (e) => {
        setCurrentIndex(e);
        {
            mappedData && mappedData.length > 0 && mappedData.map((item, index) => {
                if (e === index) {
                    setSelected(item);
                }
            }
            )
        }
    }
    const updatePlan = () => {
        userService.updateCard({
            price_id: selected.id
        })
            .then((res) => {
                props.history.push('/profileStep1');
                SuccessToast()
            })
            .catch((err) => {
                ToastHeader(err)
            })
    }

    return (
        <MainHoc isSubscription={true}>
            {loading && <Loader/>}
            <div className="col-md-6 left-panel">
                <div className="logo-wrap">
                    <img src={UtilityFunctions.getImageURL() + 'logo.svg'} alt="" />
                </div>
                <div className="left-wrap w-100">
                    <h4 className='pointer' onClick={() => props.history.goBack()}><img src={UtilityFunctions.getImageURL() + 'arrow-left.svg'} alt="left Arrow" /> Select your plan</h4>
                    <h2 className='fw-bold'>Choose your <span className='purple fw-bold'> Plan!</span></h2>
                    <h5 className='mb-5'>Select a plan based on your users.</h5>
                    <Stripe getPlan={getPlan} />
                </div>
            </div>
            <div className="col-md-6">
                <div className="slider subscription-slider v-subsricp">
                    <div className="right-panel">
                        <h4>7 Day Free Trial</h4>
                        <p>Find a plan that suits your clinic best!</p>

                        <label htmlFor="material-switch" className='material_switch'>
                            <span className={`${checked === false ? 'blue-text' : ''}`}>Monthly</span>
                            <label htmlFor="material-switch1">
                            <Switch
                                checked={checked}
                                onChange={handleChange}
                                onColor="#72CAFF"
                                onHandleColor="#2693e6"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={16}
                                width={46}
                                className="react-switch"
                                id="material-switch1"
                            />
                            </label>
                            <span className={` ${checked === true ? 'blue-text' : ''}`}>Yearly</span>
                        </label>
                    </div>

                    {/* slider starrt */}
                    {mappedData.length > 0 &&
                        //  <OwlCarousel options={options} ref={scrollRef}
                        //     events={eventsFun}
                        // >
                        <Slider {...settings} afterChange={(e) => handleChange7(e)} ref={scrollRef}>

                            {mappedData.map((item, i) => {
                                return <div id="owl-packages-new" key={i}>
                                    <div className="item">
                                        <div className="package-wrapper">
                                            <div className="top">
                                                {item.nickname}
                                            </div>
                                            <div className="package">
                                                <div className="bottom">
                                                    <div className="wrap">
                                                        <img src={UtilityFunctions.getImageURL() + (planImages && planImages[i] && planImages[i].leftImage)} alt="" />
                                                        <div>
                                                            <h2>
                                                                <sup>{item.currency_symbol}</sup>
                                                                {item.unit_amount}
                                                                {/* //{console.log(mappedData)} */}
                                                            </h2>
                                                            <h5>{`p/${item.interval}`}</h5>
                                                        </div>
                                                        <img src={UtilityFunctions.getImageURL() + (planImages && planImages[i] && planImages[i].rightImage)} alt="" />
                                                    </div>
                                                    <span className="line"></span>
                                                    <ul className='list-unstyled'>
                                                        <li><img src={UtilityFunctions.getImageURL() + 'check-circle.svg'} alt="/" /><span>{item.description}</span></li>
                                                        <li><img src={UtilityFunctions.getImageURL() + 'check-circle.svg'} alt="/" /><span>{item.sub_description}</span></li>
                                                        <li><img src={UtilityFunctions.getImageURL() + 'check-circle.svg'} alt="/" /><span>{item.trial_period}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </Slider>
                    }

                    {/* slider end */}
                    {mappedData?.length > 0 &&
                        <>
                            {/* <div className='owl-dots v-dotsset d-flex'>
                                {mappedData.map((item, index) =>
                                    <div className={selectedIndex === index ? "active owl-dot inner-dot" : "owl-dot inner-dot"} key={index} onClick={() => {
                                        setSelected(item);
                                        scrollRef?.current?.goTo(index);
                                        setSelectedIndex(index)

                                    }}>
                                        <span></span>
                                    </div>
                                )}
                            </div> */}
                        </>
                    }
                    {selectedCardId ?
                        <div className='slider-bottom'>
                            <button onClick={updatePlan} className='btn btn-cta btn-color'> Update Plan</button>
                            <p className='mt-2' style={{color:"#fff"}}>*You’ll be redirected to stripe to confirm payment</p>
                        </div>
                        : ''}
                </div>
            </div>
        </MainHoc>
    );
}

export default Subscription;
