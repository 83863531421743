import React from 'react';
import { STRING_CONSTANTS } from '../utils/constants/stringConstants'

export default function Footer() {
  return (
    <footer className="footer bg-dark text-light mt-5" >
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <p className='m-0 py-3'>{STRING_CONSTANTS.footerStrings}</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
