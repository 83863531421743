import React, { useEffect, useState } from 'react';
import { UtilityFunctions } from "../utils/UtilityFunctions";
import parse from "html-react-parser";
import { userService, cookieService } from "../_services";
import { toast } from 'react-toastify';
import ToastHeader from './toastHeader';
import Loader from 'common/Loader';

const LegalModal = (props) => {
    const [contentTerms, setContentTerms] = useState('');
    const [contentPrivacy, setContentPrivacy] = useState('');
    const [loading, setLoading] = useState(false);
    // let currentTab = props.
    useEffect(() => {
        // cookieService.removeAllCookies();
        setLoading(true);
        userService.termsAndCondition()
            .then((res) => {
                let parseContentTerms: any = parse(res[0].content);
                setLoading(false);
                return setContentTerms(parseContentTerms);
            })
            .catch(function (error) {
                setLoading(false);
                ToastHeader(error);
            });
        userService.privacyAndPolicy()
            .then((res) => {
                let parsedContentPrivacy: any = parse(res[0].content);
                setLoading(false);
                return setContentPrivacy(parsedContentPrivacy);
            })
            .catch(function (error) {
                setLoading(false);
                ToastHeader(error);
            });
        
    }, [])
    return (
        <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            {loading && <Loader />}
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                <div className="modal-content" style={{
                    background: 'rgb(7 16 50)'
                    // , maxWidth: 654, maxHeight: 754 
                }}>
                    <div className="modal-header justify-content-center border-0 p-0">
                        <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                            <li className="nav-item border-0" role="presentation">
                                <button className={`bg-transparent nav-link border-0 py-0 ${props.activeTabState === "terms" ? "active" : ""}`} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Terms & Conditions</button>
                            </li>
                            <li className="nav-item border-0" role="presentation">
                                <button className={`bg-transparent nav-link border-0 py-0 ${props.activeTabState === "privacy" ? "active" : ""}`} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Privacy Policy</button>
                            </li>
                        </ul>
                    </div>
                    <span className="line my-4"></span>
                    <div className="modal-body">
                        <div className="tab-content" id="myTabContent">
                            <div className={`tab-pane fade ${props.activeTabState === "terms" ? "show active" : ""}`} id="home" role="tabpanel" aria-labelledby="home-tab">
                                {contentTerms}
                            </div>
                            <div className={`tab-pane fade  ${props.activeTabState === "privacy" ? "show active" : ""}`} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                {contentPrivacy}
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer border-0 align-items-end">
                        <button className='btn btn-cta' data-bs-dismiss="modal" style={{ width: 279 }}> Done</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LegalModal;