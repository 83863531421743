import React, { useEffect, useRef, useState } from "react";
import { UtilityFunctions } from "utils/UtilityFunctions";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import Stripe from "../../stripe";
import { userService } from "_services";
import ToastHeader from "components/toastHeader";
import Switch from "react-switch";
import SuccessToast from "components/successToast";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { Link } from 'react-router-dom';
// import { UtilityFunctions } from 'utils/UtilityFunctions';
// import ToastHeader from "components/toastHeader";
// import SuccessToast from 'components/successToast';
// import { userService } from '_services';

const Subscription = (props) => {
  const planImages = [
    {
      leftImage: "leaf-g-left.svg",
      rightImage: "leaf-g-right.svg",
    },
    {
      leftImage: "leaf-y-left.svg",
      rightImage: "leaf-y-right.svg",
    },
    {
      leftImage: "leaf-r-left.svg",
      rightImage: "leaf-r-right.svg",
    },
    {
      leftImage: "leaf-r-left.svg",
      rightImage: "leaf-r-right.svg",
    },
  ];
  const MONTH = "month";
  const YEAR = "year";
  const [monthlyData, setMonthlyData] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);
  const [mappedData, setMappedData] = useState([]);
  const [checked1, setChecked1] = useState(false);
  const [index, setIndex] = useState(0);
  const [selectedCardId, setSelectedCardId] = useState("");
  const [planType, setPlanType] = useState("");
  const [selected, setSelected] = useState<any>(null);
  const [isDashboard, setIsDashboard] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isDashboardCheck, setIsDashboardCheck] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [priceId, setPriceId] = useState('')

  const scrollRef = useRef(null);

  useEffect(() => {
    userService
      .getProducts()
      .then((res) => {
        let month = res && res.data.filter((i) => i.interval === MONTH);
        setMappedData(month);
        setMonthlyData(month);
        let year = res && res.data.filter((i) => i.interval === YEAR);
        setYearlyData(year);
        userService
          .selectedPlan()
          .then((res) => {
            setSelectedCardId(res.data.data[0].plan.id);
            setPlanType(res.data.data[0].plan.interval.toUpperCase());
          })
          .catch((err) => {
            //ToastHeader(err);
          });
      })
      .catch(function (error) {
        ToastHeader(error);
      });
  }, []);
 useEffect(() => {
        if (selectedCardId && planType) {
            if (planType === 'MONTH') {
                monthlyData.map((item, i) => {
                    if (item.id === selectedCardId) {
                        scrollRef?.current?.slickGoTo(i)
                    }
                })
            }
            else {
                setChecked1(true);
                setMappedData(yearlyData);
                yearlyData.map((item, i) => {
                    if (item.id === selectedCardId) {
                        scrollRef?.current?.slickGoTo(i)
                    }

                })
            }
        }
    }, [planType])


  const handleChange1 = (checked1) => {
    if (checked1) {
      setSelected(yearlyData[currentIndex]);
      setMappedData(yearlyData);
      setChecked1(checked1);
    } else {
      setSelected(monthlyData[currentIndex]);
      setMappedData(monthlyData);
      setChecked1(checked1);
    }
  };

  const options = {
    items: 1,
    nav: false,
    //loop:true
    loop: false,
    margin: 0,
    center: true,
    autoplay: false,
    stagePadding: 100,
    navText: ["", ""],
    dots: false,
    responsive: {
      0: {
        items: 1,
        stagePadding: 0,
      },
      600: {
        items: 1,
        stagePadding: 0,
      },
      1000: {
        items: 1,
      },
    },
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    // variableWidth: true,
    autoplay: false,
    centerMode: true,
    centerPadding: "220px",
    adaptiveHeight: true,

    responsive: [
      {
        breakpoint: 1900,
        settings: {
          centerPadding: "220px",
        },
      },
      {
        breakpoint: 1800,
        settings: {
          centerPadding: "230px",
        },
      },
      {
        breakpoint: 1700,
        settings: {
          centerPadding: "230px",
        },
      },
      {
        breakpoint: 1600,
        settings: {
          centerPadding: "240px",
        },
      },
      {
        breakpoint: 1500,
        settings: {
          centerPadding: "220px",
        },
      },
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "170px",
        },
      },
      {
        breakpoint: 1350,
        settings: {
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 1280,
        settings: {
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          centerPadding: "250px",
        },
      },
      {
        breakpoint: 1040,
        settings: {
          centerPadding: "240px",
        },
      },
      {
        breakpoint: 870,
        settings: {
          centerPadding: "180px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: "0",
        },
      },
    ],
  };
const handleChange7 = (e) => {
  setCurrentIndex(e);
        {
            mappedData && mappedData.length > 0 && mappedData.map((item, index) => {
                if (e === index) {
                    setSelected(item);
                }
            }
            )
        }
    }
  const eventsFun = {
    // onDragged: function (event) {...},
    onChanged: function (event) {
      event.preventDefault();

      //selectedFunction(event);
      // console.log(test && test[0]);
      //console.log("dffggfg",event);
      // if(event.page.index>-1){
      // setSelectedIndex(Number(event?.page?.index) || 0)
      // setPriceId(mappedData?.[(Number(event?.page?.index) || 0)]?.id);
      // }

      // if(event.page.index > -1) {
      // setSelected(mappedData[event.page.index])
      // }
    },
  };
  const selectedFunction = (event) => {
    const Products_Name =
      mappedData &&
      mappedData.map((i, ev) => {
        return { id: ev, value: i.nickname };
      });

    if (mappedData && mappedData.length > 0 && event.page.index > -1) {
      var store = mappedData.filter(
        (i) =>
          i.nickname ===
          (event &&
            event.page &&
            Products_Name[event.page.index] &&
            Products_Name[event.page.index].value)
      )[0];
      //console.log('monthly selected', store);
      if (store) {
        //console.log('monthly selected' , store);
        setSelected(store);
      }
    }
    // setSelected(store);
    // console.log('store', store);
  };
  const updatePlan = () => {
      //  console.log(selected);
    userService
      .updateCard({
        price_id: selected.id
      })
      .then((res) => {
        SuccessToast();
      })
      .catch((err) => {
        ToastHeader(err);
      });
  };
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };
  return (
    <div className="subscription-wrap admin-side">
      <div className="p-0">
        <div className="row gx-0 bg-blue">
          <div className="col left-panel">
            <div className="left-wrap w-100">
              <h5 className="fw-bold">Card & Payment</h5>
              {/* <h5 className='mb-5'></h5> */}
              <Stripe
                isDashboard={isDashboard}
                setIsDashboard={setIsDashboard}
                isDashboardCheck= {isDashboardCheck}
              />
            </div>
          </div>
          <div className="col right-panel-grid">
            {/* <div className="slider subscription-slider">
                                                    <div className="right-panel">
                                                        <h4>7 Day Free Trial</h4>
                                                        <p>Find a plan that suits your clinic best!</p>

                                                        <label htmlFor="material-switch">
                                                            <span className={`mx-4 ${checked === false ? 'blue-text' : ''}`}>Monthly</span>
                                                            <Switch
                                                                checked={checked}
                                                                onChange={handleChange}
                                                                onColor="#72CAFF"
                                                                onHandleColor="#2693e6"
                                                                handleDiameter={20}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={16}
                                                                width={46}
                                                                className="react-switch"
                                                                id="material-switch"
                                                            />
                                                            <span className={`mx-4 ${checked === true ? 'blue-text' : ''}`}>Yearly</span>
                                                        </label>
                                                    </div>

                                                    {mappedData.length > 0 && <OwlCarousel options={options}
                                                        events={eventsFun}
                                                    >
                                                        {mappedData.map((item, i) => {
                                                            return (<div id="owl-packages-new" key={i}>
                                                                <div className="item">
                                                                    <div className="package-wrapper">
                                                                        <div className="top">
                                                                            {item.nickname}
                                                                        </div>
                                                                        <div className="package">
                                                                            <div className="bottom">
                                                                                <div className="wrap">
                                                                                    <img src={UtilityFunctions.getImageURL() + (planImages && planImages[i] && planImages[i].leftImage)} alt="" />
                                                                                    <div>
                                                                                        <h2>
                                                                                            <sup>{item.currency_symbol}</sup>
                                                                                            {item.unit_amount}
                                                                                        </h2>
                                                                                        <h5>{`p/${item.interval}`}</h5>
                                                                                    </div>
                                                                                    <img src={UtilityFunctions.getImageURL() + (planImages && planImages[i] && planImages[i].rightImage)} alt="" />
                                                                                </div>
                                                                                <span className="line"></span>
                                                                                <ul className='list-unstyled'>
                                                                                    <li><img src={UtilityFunctions.getImageURL() + 'check-circle.svg'} alt="/" /><span>{item.description}</span></li>
                                                                                    <li><img src={UtilityFunctions.getImageURL() + 'check-circle.svg'} alt="/" /><span>{item.sub_description}</span></li>
                                                                                    <li><img src={UtilityFunctions.getImageURL() + 'check-circle.svg'} alt="/" /><span>{item.trial_period}</span></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>)
                                                        })}
                                                    </OwlCarousel>}

                                                </div> */}
            <div className="right-panel">
              <div className="d-grid d-sm-flex justify-content-sm-between justify-content-center ">
                <div className="left-side-h">
                  <h5 className="">My Subscription</h5>
                </div>
                <div className="right-side-su">
                  {/* <label htmlFor="material-switch">
                    <span>Monthly</span> */}
                    <label htmlFor="material-switch" className='material_switch'>
                            <span className={`${checked1 === false ? 'blue-text' : ''}`}>Monthly</span>
                            <label htmlFor="material-switch1">
                    <Switch
                      checked={checked1}
                      onChange={handleChange1}
                      onColor="#72CAFF"
                      onHandleColor="#2693e6"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={16}
                      width={46}
                      className="react-switch"
                      id="material-switch1"
                    />
                    </label>
                     <span className={`${checked1 === false ? 'blue-text' : ''}`}>Yearly</span>
                    {/* <span>Yearly</span> */}
                  </label>
                </div>
              </div>
            </div>
            <div className="slider subscription-slider dashboard-slider">
              {/* slider starrt */}
              {mappedData.length > 0 && (
                // <OwlCarousel
                //   ref={scrollRef}
                //   options={options}
                //   events={eventsFun}
                // >
                //   {mappedData.map((item, i) => {
                //     return (
                //       <div id="owl-packages-new" key={i}>
                //         <div className="item">
                //           <div className="package-wrapper">
                //             <div className="top">{item.nickname}</div>
                //             <div className="package">
                //               <div className="bottom">
                //                 <div className="wrap">
                //                   <img
                //                     src={
                //                       UtilityFunctions.getImageURL() +
                //                       (planImages &&
                //                         planImages[i] &&
                //                         planImages[i].leftImage)
                //                     }
                //                     alt=""
                //                   />
                //                   <div>
                //                     <h2>
                //                       <sup>{item.currency_symbol}</sup>
                //                       {item.unit_amount}
                //                     </h2>
                //                     <h5>{`p/${item.interval}`}</h5>
                //                   </div>
                //                   <img
                //                     src={
                //                       UtilityFunctions.getImageURL() +
                //                       (planImages &&
                //                         planImages[i] &&
                //                         planImages[i].rightImage)
                //                     }
                //                     alt=""
                //                   />
                //                 </div>
                //                 <span className="line first-line"></span>
                //                 <ul className="list-unstyled">
                //                   <li>
                //                     <img
                //                       src={
                //                         UtilityFunctions.getImageURL() +
                //                         "check-circle.svg"
                //                       }
                //                       alt="/"
                //                     />
                //                     <span>{item.description}</span>
                //                   </li>
                //                   <li>
                //                     <img
                //                       src={
                //                         UtilityFunctions.getImageURL() +
                //                         "check-circle.svg"
                //                       }
                //                       alt="/"
                //                     />
                //                     <span>{item.sub_description}</span>
                //                   </li>
                //                   <li>
                //                     <img
                //                       src={
                //                         UtilityFunctions.getImageURL() +
                //                         "check-circle.svg"
                //                       }
                //                       alt="/"
                //                     />
                //                     <span>{item.trial_period}</span>
                //                   </li>
                //                 </ul>
                //                 <span className="line bottomli"></span>
                //                 <span className="selected_plan">
                //                   Selected Plan
                //                 </span>
                //               </div>
                //             </div>
                //           </div>
                //         </div>
                //       </div>
                //     );
                //   })}
                // </OwlCarousel>
                <Slider {...settings} ref={scrollRef} afterChange={(e) => handleChange7(e)} >
                  {mappedData.map((item, i) => {
                    return (
                      <div id="owl-packages-new" key={i}>
                        <div className="item">
                          <div className="package-wrapper">
                            <div className="top">{item.nickname}</div>
                            <div className="package">
                              <div className="bottom">
                                <div className="wrap">
                                  <img
                                    src={
                                      UtilityFunctions.getImageURL() +
                                      (planImages &&
                                        planImages[i] &&
                                        planImages[i].leftImage)
                                    }
                                    alt=""
                                  />
                                  <div>
                                    <h2>
                                      <sup>{item.currency_symbol}</sup>
                                      {item.unit_amount}
                                      {/* //{console.log(mappedData)} */}
                                    </h2>
                                    <h5>{`p/${item.interval}`}</h5>
                                  </div>
                                  <img
                                    src={
                                      UtilityFunctions.getImageURL() +
                                      (planImages &&
                                        planImages[i] &&
                                        planImages[i].rightImage)
                                    }
                                    alt=""
                                  />
                                </div>
                                <span className="line"></span>
                                <ul className="list-unstyled">
                                  <li>
                                    <img
                                      src={
                                        UtilityFunctions.getImageURL() +
                                        "check-circle.svg"
                                      }
                                      alt="/"
                                    />
                                    <span>{item.description}</span>
                                  </li>
                                  <li>
                                    <img
                                      src={
                                        UtilityFunctions.getImageURL() +
                                        "check-circle.svg"
                                      }
                                      alt="/"
                                    />
                                    <span>{item.sub_description}</span>
                                  </li>
                                  <li>
                                    <img
                                      src={
                                        UtilityFunctions.getImageURL() +
                                        "check-circle.svg"
                                      }
                                      alt="/"
                                    />
                                    <span>{item.trial_period}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              )}
              {/* slider end */}
              <div className="slider-bottom">
                <button onClick={updatePlan} className="btn btn-cta btn-color">
                  {" "}
                  Update Plan
                </button>
                <p className="mt-2">
                  *You’ll be redirected to stripe to confirm payment
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Subscription;
