import React from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import $ from "jquery";
import { UtilityFunctions } from "utils/UtilityFunctions";
const slidedata = [
  {
    imgurl: "https://wowslider.com/sliders/demo-77/data1/images/road220058.jpg",
    title: "Your Personal Self Care Companion",
    des: "Meditate learn and live a healthy balanced lifestyle",
  },
  {
    imgurl: "https://wowslider.com/sliders/demo-77/data1/images/road220058.jpg",
    title: "Your Personal Self Care Companion1",
    des: "Meditate learn and live a healthy balanced lifestyle",
  },
  {
    imgurl: "https://wowslider.com/sliders/demo-77/data1/images/road220058.jpg",
    title: "Your Personal Self Care Companion2",
    des: "Meditate learn and live a healthy balanced lifestyle",
  },
  {
    imgurl: "https://wowslider.com/sliders/demo-77/data1/images/road220058.jpg",
    title: "Your Personal Self Care Companion3",
    des: "Meditate learn and live a healthy balanced lifestyle",
  },
];
const Slider = () => {
  const sliderHandler = () => {
    function detect_active() {
      // get active
      var get_active = $("#dp-slider .dp_item:first-child").data("class");
      // $("#dp-slider .dp_item .slider-content").removeClass("active");
      // $("#dp-slider .dp_item:first-child .slider-content").addClass("active");
      $("#dp-dots li").removeClass("active");
      $("#dp-dots li[data-class=" + get_active + "]").addClass("active");
      $("#dp-content li").removeClass("active");
      $("#dp-content li[data-class=" + get_active + "]").addClass("active");
    }
    // function NextPlay() {

    // }
    $("#dp-next").click(function () {
      var total = $(".dp_item").length;
      $("#dp-slider .dp_item:first-child")
        .hide()
        .appendTo("#dp-slider")
        .fadeIn();
      $.each($(".dp_item"), function (index, dp_item) {
        $(dp_item).attr("data-position", index + 1);
      });
      detect_active();
    });
    //   setInterval(() => {
    //   NextPlay();
    // }, 5000);

    $("#dp-prev").click(function () {
      var total = $(".dp_item").length;
      $("#dp-slider .dp_item:last-child")
        .hide()
        .prependTo("#dp-slider")
        .fadeIn();
      $.each($(".dp_item"), function (index, dp_item) {
        $(dp_item).attr("data-position", index + 1);
      });

      detect_active();
    });

    $("#dp-dots li").click(function () {
      $("#dp-dots li").removeClass("active");
      $(this).addClass("active");
      var get_slide = $(this).attr("data-class");
      $("#dp-slider .dp_item[data-class=" + get_slide + "]")
        .hide()
        .prependTo("#dp-slider")
        .fadeIn();
      $.each($(".dp_item"), function (index, dp_item) {
        $(dp_item).attr("data-position", index + 1);
      });
    });
  };

  React.useEffect(() => {
    sliderHandler();
  }, []);
  //  setInterval(() => {
  //   changeSlide();
  // }, 5000);

  return (
    <div className="col-md-6">
      <div className="slider slider-home">
        <div className="right-panel"></div>

        <div id="slider">
          <div className="dp-wrap">
            <div id="dp-slider">
              <div className="dp_item" data-class="1" data-position="1">
                <div className="dp-img">
                  <img
                    className="img-fluid"
                    src="https://png.pngtree.com/thumb_back/fh260/background/20210115/pngtree-neon-light-background-image_533528.jpg"
                    alt="Slide 1"
                  />
                </div>
                
              </div>

              <div className="dp_item" data-class="2" data-position="2">
                <div className="dp-img">
                  <img
                    className="img-fluid"
                    src="https://static.vecteezy.com/system/resources/previews/001/849/553/original/modern-gold-background-free-vector.jpg"
                    alt="Slide 2"
                  />
                </div>
               
              </div>

              <div className="dp_item" data-class="3" data-position="3">
                <div className="dp-img">
                  <img
                    className="img-fluid"
                    src="https://img.freepik.com/premium-vector/abstract-dynamic-blue-orange-background_67845-1390.jpg?w=360"
                    alt="Slide 3"
                  />
                </div>
                
              </div>

              <div className="dp_item" data-class="4" data-position="4">
                <div className="dp-img">
                  <img
                    className="img-fluid"
                    src="https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-modern-double-color-futuristic-neon-background-image_351866.jpg"
                    alt="Slide 4"
                  />
                </div>
              </div>
            </div>
          </div>
          <span id="dp-next">
            <img src="/assets/images/next.svg" alt="" />
          </span>

          <span id="dp-prev">
            <img src="/assets/images/prev.svg" alt="" />
          </span>
          <ul id="dp-content">
            <li data-class="1" className="active">
              <div className="slider-content">
                <h4>Your Personal Self Care Companion </h4>
                <p>Meditate learn and live a healthy balanced lifestyle</p>
              </div>
            </li>
            <li data-class="2">
              <div className="slider-content">
                <h4>Your Personal Self Care Companion 1</h4>
                <p>Meditate learn and live a healthy balanced lifestyle</p>
              </div>
            </li>
            <li data-class="3">
              <div className="slider-content">
                <h4>Your Personal Self Care Companion 2</h4>
                <p>Meditate learn and live a healthy balanced lifestyle</p>
              </div>
            </li>
            <li data-class="4">
              <div className="slider-content">
                <h4>Your Personal Self Care Companion 3</h4>
                <p>Meditate learn and live a healthy balanced lifestyle</p>
              </div>
            </li>
          </ul>
          <ul id="dp-dots">
            <li data-class="1" className="active"></li>
            <li data-class="2"></li>
            <li data-class="3"></li>
            <li data-class="4"></li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Slider;
