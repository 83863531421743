import Loader from 'common/Loader';
import SuccessToast from 'components/successToast';
import ToastHeader from 'components/toastHeader';
import ValidationErrorToast from 'components/validationErrorToast';
import React, { useState } from 'react';
import { STRING_CONSTANTS } from 'utils/constants/stringConstants';
import { UtilityFunctions } from 'utils/UtilityFunctions';
import { userService } from '_services';

const PasswordSecurity = (props) => {
    const [newPwd, setNewPwd] = useState('');
    const [currentPwd, setCurrentPwd] = useState('');
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [readOnly, setReadOnly] = useState(true);
    const [enableButton3, setEnableButton3] = useState(true);
    const [loading, setLoading] = useState(false);
    const [btnClick, setBtnClick] = useState(false);

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const toggleConfirmPassword = () => {
        if (confirmPasswordType === "password") {
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }
    const handleChange = () => {
        props.setChecked(!props.checked);
        setLoading(true);
        userService.updateProfile({
            otp_enabled: !props.checked
        })
            .then((res) => {
                setLoading(false);
                SuccessToast()
            })
            .catch((err) => {
                setLoading(false);
                ToastHeader(err);
            })
    }
    const savePwdChanges = () => {
        setBtnClick(true);
        const pwdParams = {
            old_password: currentPwd,
            new_password: newPwd
        }
        //if (newPwd === confNewPwd && currentPwd !== '' && newPwd !== '' && confNewPwd !== '') {
            if (UtilityFunctions.isEmptyOrNullOrUndefined(newPwd)) {
                ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_password, STRING_CONSTANTS.validationString.req_password);
            }
            else if (UtilityFunctions.isEmptyOrNullOrUndefined(currentPwd)) {
                ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_password, STRING_CONSTANTS.validationString.req_password);
            }
            else if (UtilityFunctions.validatePassword(newPwd)) {
                ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_password, STRING_CONSTANTS.validationString.req_validate_password);
            }
            else if (currentPwd === newPwd) {
                ValidationErrorToast( STRING_CONSTANTS.formLabelString.label_password, STRING_CONSTANTS.validationString.req_not_same_password);
            }
            else{
        if (currentPwd !== '' && newPwd !== '') {
            if (enableButton3) {
                setEnableButton3(false);
                setLoading(true);
                userService.changePwd(pwdParams)
                    .then(() => {
                        setEnableButton3(true);
                        setCurrentPwd('');
                        setNewPwd('')
                        SuccessToast();
                        setLoading(false);
                    })
                    .catch((err) => {
                        setEnableButton3(true);
                        setLoading(false);
                        ToastHeader(err);
                    })
            }
            setBtnClick(false);
        }}
    }
    window.history.pushState(null, "", window.location.href);
        window.onpopstate = function() {
          window.history.pushState(null, "", window.location.href);
        };
    return (
        <div className="admin-wrap">
            {loading && <Loader />}
        <div className='justify-content-center details-container mt-0'>
            <div className="d-grid" >
                <div className='v-all-form'>
                    <div className='v-mid-form'>
                        <div className="form-floating-custom-profile mb-4 pb-1">
                            <input type={passwordType}
                                autoComplete="disabled"
                                //readOnly={readOnly}
                                //onFocus={() => setReadOnly(false)}
                                //onBlur={() => setReadOnly(true)}
                                className='' placeholder="Your Current Password" value={currentPwd || ''} onChange={(e) => setCurrentPwd(e.target.value)} disabled={!props.checkAccess} />
                            <label>Current Password</label>
                            <img onClick={togglePassword} src={UtilityFunctions.getImageURL() + `${passwordType === "password" ? "eye_close_dark.svg" : "eye_open_dark.svg"}`} alt=""/>
                            {/* <i onClick={togglePassword} className={passwordType === "password" ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i> */}
                        </div>

                        <div 
                        // className="form-floating-custom-profile mb-4 pb-1"
                        className={!btnClick ? "form-floating-custom-profile mb-4 pb-1" : !UtilityFunctions.validatePassword(newPwd)? "form-floating-custom-profile mb-4 pb-1" : "form-floating-custom-profile mb-4 pb-1 red-bg red-bg-xs"}
                        
                        >
                            <input type={confirmPasswordType}
                                autoComplete="disabled"
                                //readOnly={readOnly}
                                //onFocus={() => setReadOnly(false)}
                               // onBlur={() => setReadOnly(true)}
                                className={!btnClick ? "" : !UtilityFunctions.validatePassword(newPwd)? "" : "is-invalid-contact is-invalid"}
                                 placeholder="Add New Password" value={newPwd} onChange={(e) => {setNewPwd(e.target.value); setBtnClick(false);}} disabled={!props.checkAccess} />
                            <label>New Password</label>
                            <img onClick={toggleConfirmPassword} src={UtilityFunctions.getImageURL() + `${confirmPasswordType === "password" ? "eye_close_dark.svg" : "eye_open_dark.svg"}`} alt="" />
                            {/* <i onClick={toggleConfirmPassword} className={confirmPasswordType === "password" ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i> */}
                        </div>
                        {/* <div className="form-floating-custom-profile mb-4">
                    <input type="text" className='' placeholder="Confirm New Password" value={confNewPwd} onChange={(e) => setConfNewPwd(e.target.value)} disabled={!checkAccess} />
                    <label>Confirm Password</label>
                </div> */}
                        <div className="mb-4 form-checkbox">
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <label>Enable 2FA Login</label>
                                </div>
                                <div className="col-4 position-checkbox p-0">
                                    <label className="form-check-label">
                                        <input
                                            type="checkbox"
                                            className=" form-check-input styled-checkbox"
                                            id="check1"
                                            checked={props.checked}
                                            onChange={handleChange}
                                            disabled={!props.checkAccess}
                                        />
                                        <label className="font-weight-bold" htmlFor={'check1'}></label>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-grid text-center">
                        <button className='btn btn-cta btn-color' onClick={savePwdChanges} disabled={!props.checkAccess}> Save Changes </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
    )
}
export default PasswordSecurity;