import Loader from "common/Loader";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { UtilityFunctions } from "../utils/UtilityFunctions";
import { userService, cookieService } from '../_services';
import ToastHeader from "./toastHeader";

const Logout = (props) => {
    const [loading, setLoading] = useState(false);
    const handleClick = (e) => {
        e.preventDefault();
        setLoading(true);
        userService.logout()
            .then(() => {
                cookieService.removeAllCookies();
                sessionStorage.clear();
                props.history.push('/signin');
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                ToastHeader(err);
            })
    }

    return (

        <div className="modal fade" id="logout" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            {loading && <Loader />}
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <h5 className="modal-title text-center" id="exampleModalLabel">Do you really want to logout.</h5>
                    </div>
                    <div className="modal-footer">
                        <img src={UtilityFunctions.getImageURL() + "faq-page-image.svg"} alt="FAQ" />
                        <div>
                            <button type="button" className="btn btn-outline-secondary me-4" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-outline-info" onClick={handleClick} data-bs-dismiss="modal">logout</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
};
export default withRouter(Logout);
