import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { cookieService } from '../_services/cookie.service';
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";

export const UnauthRouter = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            const userData = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token) || null;
            return !userData ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: STRING_CONSTANTS.navigationString.index }} />
            );
        }}
    />
);

export default UnauthRouter;
