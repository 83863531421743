import React, { useEffect, useState } from "react";
import { UtilityFunctions } from "../utils/UtilityFunctions";
import parse from "html-react-parser";
import { userService } from "../_services";
import ToastHeader from "./toastHeader";
import { Link } from "react-router-dom";

const FaqModal = (props) => {
  const [faqs, setFaqs] = useState([]);
  useEffect(() => {
    userService
      .faqContent()
      .then((res) => {
        setFaqs(res && res.results)
      })
      .catch(function (error) {
        ToastHeader(error);
      });
  }, []);

  return (
    <div className="modal fade" id="faqmodal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <span  className="capture_close show_mob" data-bs-toggle="modal"
            data-bs-target="#faqmodal">&times;</span>
          <div className="modal-body">
            <h2 className="modal-title" id="exampleModalLabel">FAQ’s</h2>
            <p>Need help or answers? FInd them here...</p>

            <div className="accordion" id="accordionExample">
              {faqs.map((value, index) => {
                let store = value && value.question;
                let answer = value && value.answer == null ? value.answer : parse(value.answer);
                return (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={"headingOne" + index}>
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + index} aria-expanded="false" aria-controls={"collapseOne" + index}>
                        {parse(store)}
                      </button>
                    </h2>
                    <div id={"collapseOne" + index} className="accordion-collapse collapse" aria-labelledby={"headingOne" + index} data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>{answer}</p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="modal-footer">
            <img src={UtilityFunctions.getImageURL() + "faq-page-image.svg"} alt="FAQ" />
            <p>Couldn’t find what you were after?</p>
            <p>Contact us at <a href="mailto:mindgeni@customerservice.com"><span className='mail-to'>mindgeni@customerservice.com</span></a></p>
          </div>
        </div>
      </div>
    </div >

  );
};
export default FaqModal;
