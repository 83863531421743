import React, { useEffect, useState } from "react";
import { UtilityFunctions } from "../utils/UtilityFunctions";
import parse from "html-react-parser";
import { userService } from "../_services";
import ToastHeader from "./toastHeader";
import { Link } from "react-router-dom";
import SuccessToast from "./successToast";
import ValidationErrorToast from "./validationErrorToast";
import { STRING_CONSTANTS } from "utils/constants/stringConstants";

const ConfirmPwd = (props) => {
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [readOnly, setReadOnly] = useState(true);

    const toggleConfirmPassword = () => {
        if (confirmPasswordType === "password") {
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }
    const confirmPassword = () => {
        if (UtilityFunctions.isEmptyOrNullOrUndefined(props.pwd)) {
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_password, STRING_CONSTANTS.validationString.req_password);
        }
        else {
            props.confirmPwd()
        }
    }


    return (
        <div className={"modal fade" + (props.isModalOpen ? " show d-block" : " ")} id="confirmModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>props.closeButton()}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 5L5 15" stroke="#020515" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M5 5L15 15" stroke="#020515" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                    <div className="modal-body">
                        <div className="text-center mb-3"><img src={UtilityFunctions.getImageURL() + "confirmLogo.svg"} alt="Confirm" /></div>
                        <div className="text-center"><h6>Enter Password</h6></div>
                        <div className="mt-3"><p className="text-center">You must enter your password to update your </p></div>
                        <div><p className="text-center">email or mobile number</p></div>
                        <div className="form-floating-custom-profile mb-3 pb-4  mt-4">
                            <input type={confirmPasswordType}
                                autoComplete="disabled"
                                // readOnly={readOnly}
                                // onFocus={() => setReadOnly(false)}
                                // onBlur={() => setReadOnly(true)}
                                className='' placeholder="Password" value={props.pwd} onChange={(e) => props.setPwd(e.target.value)} />
                            <label>Password</label>
                            <img onClick={toggleConfirmPassword} src={UtilityFunctions.getImageURL() + `${confirmPasswordType === "password" ? "eye_close_dark.svg" : "eye_open_dark.svg"}`} alt="" />

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className='btn btn-cta btn-color' onClick={confirmPassword}> Save Changes </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ConfirmPwd;

