import ImageCrop from "common/ImageCrop";
import Loader from "common/Loader";
import SuccessToast from "components/successToast";
import WebcamCapture from "pages/webcamCapture";
import React, { useEffect, useState } from "react";
import { ToastHeader } from "react-bootstrap";
import { STRING_CONSTANTS } from "utils/constants/stringConstants";
// import { Link } from 'react-router-dom';
import { UtilityFunctions } from "utils/UtilityFunctions";
import { cookieService, userService } from "_services";

const Account = (props) => {
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [captureType, setCaptureType] = useState("");
  const [onUpload, setOnUpload] = useState(false);
  const webcamRef = React.useRef(null);
  const [camSrc, setCamSrc] = React.useState(null);
  const [permissionText, setPermissionText] = React.useState(null);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPreview(null);
    setCamSrc(imageSrc);
    closePopup();
    setCaptureType("");
      props.setFirstTimeUploaded(false);
      props.setProfileImg(imageSrc);
      props.setNewImgUploaded(true);
    
  }, [webcamRef, setCamSrc]);
  const closePopup = () => {
    setTimeout(() => {
      setOnUpload(false);
    }, 200);
  };
  const verifyEmail = () => {
    setLoading(true);
    userService
      .verifyEmail({
        email: props.email,
      })
      .then((res) => {
        setLoading(false);
        setEmailVerified(true);
      })
      .catch((err) => {
        setLoading(false);
        ToastHeader(err);
      });
  };
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };

  return (
    <div className="admin-wrap">
      {loading && <Loader />}
      
      {emailVerified ? (
        <div className="v-alert mysucess-alert">
          <p className="mb-0">
            <b>Success!</b> Open your email & click verify to get your account
            verified with us.
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setEmailVerified(false)}
              cursor="pointer"
            >
              <path
                d="M12 4L4 12"
                stroke="#121F56"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4 4L12 12"
                stroke="#121F56"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </p>
        </div>
      ) : (
        ""
      )}
      <div className="justify-content-center details-container">
        <div className="d-grid position-relative">
          <div className="image-wrapper single-line m-auto">
            {/* {captureType == "capture" && (
              <>
                {permissionText ? (
                  <span
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      height: "100%",
                      paddingLeft: "20px",
                      paddingTop: "25px",
                    }}
                  >
                    {" "}
                    {permissionText}{" "}
                  </span>
                ) : (
                  <div className="webcam_modal">
                    <WebcamCapture
                      onUserMediaError={(err) => {
                        if (err) {
                          setPermissionText(
                            "We need camera permission in order to perform this action, Please allow permissions from site Settings."
                          );
                        }
                      }}
                      width={138}
                      height={181}
                      ref={webcamRef}
                    />
                    <button className="btn btn-cta" onClick={capture}>
                      {" "}
                      Capture Image
                    </button>
                  </div>
                )}
              </>
            )} */}
            <div className="image-upload">
              {/* <label>
                                <label className="editButton" htmlFor="upload-profile-button">
                                    <img src={UtilityFunctions.getImageURL() + 'edit-pencil.svg'} alt="" />
                                </label>
                                {props.profileImg === null ? <img alt="" className="image-show" src={UtilityFunctions.getImageURL() + 'bussiness-logo.svg'} /> : props.showProfileImg === null ? <img alt="" className="image-show" src={props.profileImg} /> : <img alt="" className="image-show" src={props.showProfileImg} />}
                            </label>
                            <input type="file" disabled={!props.checkAccess} onChange={(e) => props.profileImageUploadCheck(e)} id="upload-profile-button" style={{ display: 'none' }} /> */}
              <label className="editButton" onClick={() => setOnUpload(true)}>
                <img
                  src={UtilityFunctions.getImageURL() + "edit-pencil.svg"}
                  alt=""
                />
                          </label>
                          {/* { console.log(camSrc)
                          } */}
              {camSrc ? (
                <img alt="" className="image-show" src={camSrc} />
                          ) : (
                <img
                  alt=""
                  className="image-show"
                  src={
                    props.profileImg == null
                      ? UtilityFunctions.getImageURL() + "logo.svg"
                      : preview != null
                      ? preview
                      : props.firstTimeUploaded
                      ? URL.createObjectURL(props.profileImg)
                      : props.profileImg
                  }
                />
              )}
              {/* {onUpload && (
                <div className="image_upload_option">
                  <span className="close" onClick={() => setOnUpload(false)}>
                    &times;
                  </span>
                  <label
                    onClick={() => {
                      setCaptureType("capture");
                      closePopup();
                    }}
                  >
                    Open Camera
                  </label>
                  <label htmlFor="image_id" onClick={closePopup}>
                    Select from device
                  </label>
                </div>
              )} */}

              <ImageCrop
                ratio={16 / 16}
                croppedImage={(imgSrc, file) => {
                  //setSelectedFile(file)
                  setCamSrc(null);
                  setPreview(imgSrc);
                  props.setImgUploaded(true);
                  props.setFirstTimeUploaded(true);
                  props.setProfileImg(file);
                  props.setNewImgUploaded(true);
                }}
                size={15}
              />
            </div>
          </div>
          <div className="v-all-form">
            <div className="v-mid-form">
              <div
                // className="form-floating-custom-profile mb-4 pb-1"
               className={
                    props.email.length > 0
                      ? "form-floating-custom-profile mb-4 pb-1"
                      : "form-floating-custom-profile mb-4 pb-1 red-bg  red-bg-xs"
                  }
                style={{ marginTop: 44 }}
              >
                <input
                  type="email"
                  className={
                    props.email.length > 0
                      ? ""
                      : "is-invalid-contact is-invalid"
                  }
                  placeholder="Enter email address"
                  value={props.email}
                  onChange={(e) => props.setEmail(e.target.value)}
                  disabled={!props.checkAccess}
                />
                <label>Email Address</label>
                {props.emailVerified ? (
                  ""
                ) : (
                  <>
                    <div className="v-error input-error">
                      <img
                        src={UtilityFunctions.getImageURL() + "error-alert.svg"}
                        alt=""
                      />
                      <p>Please verify your account</p>
                    </div>
                    <div className="form-link pointer" onClick={verifyEmail}>
                      Send verification link
                    </div>
                  </>
                )}
              </div>

              <div
                // className="form-floating-custom-profile mb-4 pb-1"
                className={
                    props.firstName.length > 0
                      ? "form-floating-custom-profile mb-4 pb-1"
                      : "form-floating-custom-profile mb-4 pb-1 red-bg red-bg-xs"
                  }
              >
                <input
                  type="text"
                  className={
                    props.firstName.length > 0
                      ? ""
                      : "is-invalid-contact is-invalid"
                  }
                  placeholder="Enter First Name"
                  value={props.firstName}
                  onChange={(e) => props.setFirstName(e.target.value)}
                  disabled={!props.checkAccess}
                />
                <label>First Name</label>
              </div>
              <div
                // className="form-floating-custom-profile mb-4 pb-1"
                className={
                  props.lastName.length > 0
                    ? "form-floating-custom-profile mb-4 pb-1"
                    : "form-floating-custom-profile mb-4 pb-1 red-bg red-bg-xs"
                }
              >
                <input
                  type="text"
                  className={
                    props.lastName.length > 0
                      ? ""
                      : "is-invalid-contact is-invalid"
                  }
                  placeholder="Enter Last Name"
                  value={props.lastName}
                  onChange={(e) => props.setLastName(e.target.value)}
                  disabled={!props.checkAccess}
                />
                <label>Last Name</label>
              </div>
            </div>
            <div className="d-grid text-center">
              <button
                className="btn btn-cta btn-color"
                onClick={props.updateAccountDetails}
                disabled={!props.checkAccess}
              >
                {" "}
                Save Changes{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          onUpload
            ? "modal fade show capture_modal"
            : "modal fade capture_modal"
        }
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{
          display: onUpload ? "block" : "",
          opacity: onUpload ? "initial" : "0",
        }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <span className="capture_close" onClick={() => { closePopup();  setCaptureType(null)}}>&times;</span>
            <div className="modal-body">
             {captureType == "capture" && (
              <>
                {permissionText ? (
                  <span
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      height: "100%",
                      paddingLeft: "20px",
                      paddingTop: "25px",
                    }}
                  >
                    {" "}
                    {permissionText}{" "}
                  </span>
                ) : (
                  <div className="webcam_modal">
                    <WebcamCapture
                      onUserMediaError={(err) => {
                        if (err) {
                          setPermissionText(
                            "We need camera permission in order to perform this action, Please allow permissions from site Settings."
                          );
                        }
                      }}
                      width={"100%"}
                      height={"100%"}
                      ref={webcamRef}
                    />
                    <button className="btn btn-cta" onClick={capture}>
                      {" "}
                      Capture Image
                    </button>
                  </div>
                )}
              </>
            )}
              {!captureType && (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-secondary me-4"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setCaptureType("capture");
                      //   closePopup();
                    }}
                  >
                    Open Camera
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-info"
                    onClick={() => {
                      closePopup();
                    }}
                    data-bs-dismiss="modal"
                  >
                    <label htmlFor="image_id" style={{cursor:"pointer"}}>Select from device</label>
                  </button>
                </>
              )}
            </div>
           
          </div>
        </div>
      </div>
    </div>

  );
};
export default Account;
