import React from 'react';
import { withRouter } from 'react-router-dom';

/**
 * MainHoc App container.
 */

const MainHoc = (props) => {
    const { children } = props;
    return (
        <div className='container-fluid p-0'>
            <div className={props.isSubscription ? "row gx-0 bg-blue vh-100 sub-vh-auto" : "row gx-0 bg-blue vh-100"}>
                {children}
            </div>
        </div >
    )
}
export default withRouter(MainHoc);
