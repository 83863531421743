/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { withRouter } from "react-router-dom";
import { userService } from "../_services";
import ToastHeader from "components/toastHeader";
import SuccessToast from "components/successToast";
import Loader from "common/Loader";
import InputMask from "react-input-mask";
import ValidationErrorToast from "components/validationErrorToast";
import { STRING_CONSTANTS } from "utils/constants/stringConstants";
import { UtilityFunctions } from "utils/UtilityFunctions";

const CheckOut = (props) => {
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardHolderNumber, setCardHolderNumber] = useState("XXXX XXXX XXXX");
  const [cardCvv, setCardCvv] = useState("XXX");
  const [cardId, setCardId] = useState("");
  const [cardRemoved, setCardRemoved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [creditDate, setCreditDate] = useState(null);
  const [cardExist, setCardExist] = useState(false);
  const [btnClick, setBtnClick] = useState(false);
  const [invalidExp, setInValidExp] = useState(false);
  const [btnClickSave, setBtnClickSave] = useState(false);

  const [errorObject, setErrorObject] = useState({
    card: {
      isEmpty: true,
      message: "",
    },
    cvv: {
      isEmpty: true,
      message: "",
    },
    expiry: {
      isEmpty: true,
      message: "",
    },
  });

  const stripe = useStripe();
  const elements = useElements();
  const useOptions = () => {
    const options =
      // useMemo(
      // () => (
        {
        style: {
          base: {
            fontSize: "14px",
            color: props.isDashboardCheck ? "#020515" : "#fff",
            letterSpacing: "0.025em",
            family: "'DM Sans', sans-serif",
            src: "url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap')",
            weight: "300",
            "::placeholder": {
              color: props.isDashboardCheck ? "#020515" : "#fff",
            },
          },
          invalid: {
              color: "#eb5757"
            
            },
          empty: {
            "::placeholder": {
                color: ((errorObject?.expiry?.isEmpty && errorObject?.expiry?.message) || (errorObject?.card?.isEmpty && errorObject?.card?.message) || (errorObject?.cvv?.isEmpty && errorObject?.cvv?.message) ) || btnClick? "#eb5757" : props.isDashboardCheck ? "#020515" : "#fff",
              },
              border: "0.5px solid #eb5757",
              outline: "none"
          }


        },
    }
    //   ),
    //   []
    // );
    return options;
  };
  const option = useOptions();

  useEffect(() => {
    // if (props.isDashboard){
    setLoading(true);
    userService
      .cardsList()
      .then((res) => {
        if (res.data.data.length > 0) {
          const a = res.data.data[0].card.exp_month.toString();
          const b = res.data.data[0].card.exp_year.toString().substr(-2);
          setCardHolderName(res.data.data[0].billing_details.name);
          setCardHolderNumber(
            cardHolderNumber + " " + res.data.data[0].card.last4
          );
          setCardId(res.data.data[0].id);
          setCreditDate(padLeadingZeros(Number(a + b), 4));
          setCardExist(true);
        } else {
          props.setIsDashboard(false);
          setCardRemoved(true);
        }
      })
      .catch((res) => {});
    setLoading(false);
    // }
  }, []);

  const padLeadingZeros = (num, size) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };
  const updateCard = () => {
    setBtnClickSave(true);
    setLoading(true);
    const c = creditDate.toString();
    if (
      parseInt(c.slice(0, 2)) > 12 ||
      parseInt(c.slice(0, 2)) < 1 ||
      c.slice(0, 2) === "  " || c.length === 0
    ) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_exp_date,
        STRING_CONSTANTS.validationString.req_expDate
      );
      setInValidExp(true);
      setLoading(false);
    } else {
      const param = {
        card_id: cardId,
        exp_month: c.slice(0, 2),
        exp_year: c.slice(5, 7),
      };
      userService
        .editCard(param)
        .then((res) => {
          SuccessToast();
          if (props.isDashboard === true || props.isDashboard === false) {
          } else {
            props.history.push("/profileStep1");
          }
          setBtnClickSave(false);
          setInValidExp(false);
        })
        .catch((err) => {
            ToastHeader(err);
            setInValidExp(true);
        });
        
      setLoading(false);
    }
  };
  const removeCard = (e) => {
    e.preventDefault();
    setLoading(true);
    const param = {
      card_id: cardId,
    };
    userService
      .removeSavedCard(param)
      .then((res) => {
        setCardId("");
        setCardHolderName("");
        setCardHolderNumber("");
        if (props.isDashboard) {
          props.setIsDashboard(false);
        }
        setCardExist(false);
        setCardRemoved(true);
        SuccessToast();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        ToastHeader(err);
      });
  };
  const updateWebStep = () => {
    userService
      .updateProfile({
        web_step: 2,
      })
      .then((res) => {})
      .catch((err) => {
        ToastHeader(err);
      });
    // Add Navigation to dashboard in suceess api response.
    props.history.push("/profileStep1");
  };
  const setupIntent = () => {
   // setTimeout(() => {
      setLoading(true);
      userService
        .stripeSetupintent()
        .then((res) => {
          // STEP # 3 START
          stripe
            .confirmCardSetup(res.data, {
              payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                  name: cardHolderName,
                },
              },
              // Return URL where the customer should be redirected after the authorization.
              // return_url: "http://localhost:3000"
            })
            .then(function (result) {
              if (result.error) {
                // alert('error');
              } else {
                if (result.setupIntent.status === "succeeded") {
                  if (cardRemoved) {
                    if (
                      props.isDashboard === true ||
                      props.isDashboard === false
                    ) {
                    } else {
                      updateWebStep();
                    }
                  } else {
                    updateWebStep();
                  }
                  SuccessToast();
                }
              }
              setLoading(false);
            });
          //STEP # 3 END
        })
        .catch(function (error) {
          setLoading(false);
        });
   // }, 1000);
  };
    
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (props.isDashboard || cardExist) {
      updateCard();
    } else {
      if (cardRemoved) {
        // console.log(props.priceId);
        // setStore(props.priceId);
        setBtnClick(true);
        if (errorObject?.card?.isEmpty) {
          updateErrorValue("card", true, STRING_CONSTANTS.card_errors.card_err);
          ValidationErrorToast(
            STRING_CONSTANTS.card_errors.msg,
            STRING_CONSTANTS.card_errors.card_err
          );
        }

        if (errorObject?.expiry?.isEmpty) {
          updateErrorValue(
            "expiry",
            true,
            STRING_CONSTANTS.card_errors.expiry_err
          );
          ValidationErrorToast(
            STRING_CONSTANTS.card_errors.msg,
            STRING_CONSTANTS.card_errors.expiry_err
          );
        }

        if (errorObject?.cvv?.isEmpty) {
          updateErrorValue("cvv", true, STRING_CONSTANTS.card_errors.cvv_err);
          ValidationErrorToast(
            STRING_CONSTANTS.card_errors.msg,
            STRING_CONSTANTS.card_errors.cvv_err
          );
        }
          if (errorObject?.card?.isEmpty || errorObject?.cvv?.isEmpty || errorObject?.expiry?.isEmpty) {
              return
          }

        stripe
          .createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: cardHolderName,
            },
          })
          .then((res) => {
            setBtnClick(false);
          })
          .catch((err) => {
            setBtnClick(false);
          });
        setupIntent();
      } else {
        setBtnClick(true);
        setLoading(true);
        const store = props.getPlan();
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }

        if (errorObject?.card?.isEmpty) {
          updateErrorValue("card", true, STRING_CONSTANTS.card_errors.card_err);
          ValidationErrorToast(
            STRING_CONSTANTS.card_errors.msg,
            STRING_CONSTANTS.card_errors.card_err
          );
          setLoading(false);
        }

        if (errorObject?.expiry?.isEmpty) {
          updateErrorValue(
            "expiry",
            true,
            STRING_CONSTANTS.card_errors.expiry_err
          );
          ValidationErrorToast(
            STRING_CONSTANTS.card_errors.msg,
            STRING_CONSTANTS.card_errors.expiry_err
          );
          setLoading(false);
        }

        if (errorObject?.cvv?.isEmpty) {
          updateErrorValue("cvv", true, STRING_CONSTANTS.card_errors.cvv_err);
          ValidationErrorToast(
            STRING_CONSTANTS.card_errors.msg,
            STRING_CONSTANTS.card_errors.cvv_err
          );
          setLoading(false);
        }
          if (errorObject?.expiry?.isEmpty || errorObject?.cvv?.isEmpty || errorObject?.expiry?.isEmpty) {
            setLoading(false); 
          return;
          }
        

        // STEP 0
        const data = {
          price_id: store.id, // selected plan price id.
        };
        setupIntent();
        setTimeout(()=>{
          userService
          .stripesubscription(data)
          .then((res) => {
            stripe.createPaymentMethod({
              type: "card",
              card: elements.getElement(CardNumberElement),
              billing_details: {
                name: cardHolderName,
              },
            });
            //setupIntent();
            setBtnClick(false);
            setLoading(false);
          })
          .catch(function (error) {
            ToastHeader(error);
            setBtnClick(false);
            setLoading(false);
          });
        },4000)
       

        // STEP 1

        // STEP 2  --- setTimeout placed as 2 enteries were getting created for same card
        // setTimeout(()=>{
        // userService.stripeSetupintent()
        //     .then((res) => {
        //         // console.log(res.data); // getting client secreat key

        //         // STEP # 3 START
        //         stripe.confirmCardSetup(res.data, {
        //             payment_method: {
        //                 card: elements.getElement(CardNumberElement),
        //                 billing_details: {
        //                     name: cardHolderName
        //                 },
        //             },
        //             // Return URL where the customer should be redirected after the authorization.
        //             // return_url: "http://localhost:3000"
        //         })
        //             .then(function (result) {
        //                 if (result.error) {
        //                     console.log(result.error.message);
        //                    // alert('error');
        //                 } else {
        //                     if (result.setupIntent.status === 'succeeded') {
        //                        // alert('Card Added Successfully!! Click ok to navigate to dashboard.');

        //                         userService.updateProfile({
        //                             web_step: 2
        //                         })
        //                         .then((res)=>{

        //                         })
        //                         .catch((err)=>{
        //                             ToastHeader(err);
        //                         })
        //                         // Add Navigation to dashboard in suceess api response.
        //                         props.history.push('/profile');

        //                     }
        //                 }
        //             });
        //         //STEP # 3 END
        //     })
        //     .catch(function (error) {
        //         console.log(error, 'error');
        //     });
        // },500);
        
      }
    }
  };

  const updateErrorValue = (key: any, value: any, msg?: any) => {
    const errorObj = { ...errorObject };
    errorObj[key].isEmpty = value;
    errorObj[key].message = msg || "";
    setErrorObject(errorObj);
  };

  return (
    <Fragment>
      {/* <button onClick={() => props.getPlan()}></button> */}
      {loading && <Loader />}
      <form>
        <div className="row g-2">
          <div className="col-12">
            <div
              // className="form-floating-custom mb-4 px-0"
              className={
                  !btnClick
                    ? "form-floating-custom mb-4 px-0"
                    : UtilityFunctions.isEmptyOrNullOrUndefined(cardHolderName)
                    ? "form-floating-custom mb-4 px-0 red-bg"
                    : "form-floating-custom mb-4 px-0"
                }
            >
              <input
                type="text"
                className={
                  !btnClick
                    ? ""
                    : UtilityFunctions.isEmptyOrNullOrUndefined(cardHolderName)
                    ? "is-invalid-contact is-invalid"
                    : ""
                }
                placeholder="Enter Name"
                value={cardHolderName}
                onChange={(e) => {
                  setCardHolderName(e.target.value);
                  setBtnClick(false);
                }}
                disabled={props.isDashboard || cardExist ? true : false}
              />
              <label>Card Holder Name</label>
              {!btnClick ? (
                ""
              ) : UtilityFunctions.isEmptyOrNullOrUndefined(cardHolderName) ? (
                <div className="display-error-message">
                  <img
                    alt=""
                    className="toast-icon"
                    src={
                      UtilityFunctions.getImageURL() + "error-message-icon.svg"
                    }
                  />
                  <span className="error-message-field">
                    {STRING_CONSTANTS.validationString.req_card_name}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-12">
            <div
              // className="form-floating-custom mb-4 cardNumber px-0"
              className={errorObject?.card?.message? "red-bg red-bg-small form-floating-custom mb-4 cardNumber px-0" : "form-floating-custom mb-4 cardNumber px-0"}
            >
              {props.isDashboard || cardExist ? (
                <>
                  <input
                    type="text"
                    className=""
                    placeholder="Enter Card Number"
                    value={cardHolderNumber}
                    onChange={(e) => setCardHolderNumber(e.target.value)}
                    disabled={true}
                  />
                  <label>Card Number</label>
                </>
              ) : (
                <>
                  <CardNumberElement
                    options={{
                      ...option,
                      placeholder: "Enter Card Number",
                      classes: {
                        empty: errorObject?.card?.message ? "stripe-error" : "",
                      },
                    }}
                    onChange={(evt) =>
                      updateErrorValue("card", !evt.complete, "")
                    }
                  />
                  <label>Card Number</label>
                  { errorObject?.card?.isEmpty &&
                    errorObject?.card?.message? (
                    <div className="display-error-message">
                      <img
                        alt=""
                        className="toast-icon"
                        src={
                          UtilityFunctions.getImageURL() +
                          "error-message-icon.svg"
                        }
                      />
                      <span className="error-message-field">
                        {errorObject?.card?.message}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-md">
            <div
              className={errorObject?.expiry?.message ? "red-bg red-bg-small form-floating-custom mb-4" :"form-floating-custom mb-4"}
            >
              {props.isDashboard || cardExist ? (
                <>
                  {/* <span className="expiration">
                                <input type="number" className="expiration-month" name="month" placeholder="MM" maxLength={2} value={cardExpiryMonth}  onChange={(e)=>setCardExpiryMonth(e.target.value)}/>
                                <span>/</span>
                                <input type="number" name="year" className="expiration-year" placeholder="YY" maxLength={2} value={cardExpiryYear}  onChange={(e)=>setCardExpiryYear(e.target.value)}/>
                                <label>Exp Date</label>
                            </span> */}
                  <InputMask
                    mask="99 / 99"
                    maskChar=" "
                    value={creditDate}
                    placeholder="MM / YY"
                    onChange={(e) => {setCreditDate(e.target.value); setBtnClickSave(false); setInValidExp(false);}}
                    className={`${
                        !btnClickSave
                          ? ""
                          : invalidExp
                          ? 
                          "is-invalid": ""
                      }`}
                  ></InputMask>
                  <label>Exp Date</label>
                  {!btnClickSave ? (
                ""
              ) : invalidExp ? (
                <div className="display-error-message">
                  <img
                    alt=""
                    className="toast-icon"
                    src={
                      UtilityFunctions.getImageURL() + "error-message-icon.svg"
                    }
                  />
                  <span className="error-message-field">
                    {STRING_CONSTANTS.validationString.req_expDate}
                  </span>
                </div>
              ) : (
                ""
              )}
                </>
              ) : (
                <>
                  <CardExpiryElement
                    options={{
                      ...option,
                      placeholder: "Enter Exp Date",
                      classes: {
                        empty: errorObject?.expiry?.message
                          ? "stripe-error"
                          : "",
                      },
                    }}
                    onChange={(evt) =>
                      updateErrorValue("expiry", !evt.complete, "")
                    }
                  />
                  <label>Exp Date</label>
                  {errorObject?.expiry?.isEmpty &&
                    errorObject?.expiry?.message? (
                    <div className="display-error-message">
                      <img
                        alt=""
                        className="toast-icon"
                        src={
                          UtilityFunctions.getImageURL() +
                          "error-message-icon.svg"
                        }
                      />
                      <span className="error-message-field">
                        {errorObject?.expiry?.message}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-md">
            <div className={errorObject?.cvv?.message ? "form-floating-custom mb-4 red-bg red-bg-small" : "form-floating-custom mb-4"}>
              {props.isDashboard || cardExist ? (
                <>
                  <input
                    type="text"
                    className=""
                    placeholder="Enter CVV"
                    value={cardCvv}
                    onChange={(e) => setCardCvv(e.target.value)}
                    disabled={true}
                  />
                  <label>CVV</label>
                </>
              ) : (
                <>
                    
                  <CardCvcElement
                    options={{
                      ...option,
                      placeholder: "Enter CVV",
                      classes: {
                        empty: errorObject?.cvv?.message ? "stripe-error" : "",
                      },
                    }}
                    onChange={(evt) =>
                      updateErrorValue("cvv", !evt.complete, "")
                    }
                  />
                  <label>CVV</label>
                  { errorObject?.cvv?.isEmpty &&
                    errorObject?.cvv?.message ? (
                    <div className="display-error-message">
                      <img
                        alt=""
                        className="toast-icon"
                        src={
                          UtilityFunctions.getImageURL() +
                          "error-message-icon.svg"
                        }
                      />
                      <span className="error-message-field">
                        {errorObject?.cvv?.message}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="d-grid text-center">
          {props.isDashboard || cardExist ? (
            <>
              {" "}
              <button
                onClick={handleSubmit}
                className="btn btn-cta btn-color"
                disabled={creditDate?.length === 4 ? true : false}
              >
                {" "}
                Save Changes
              </button>
              <br />{" "}
              <button
                onClick={removeCard}
                disabled={!stripe}
                className="btn btn-cta btn-color"
              >
                {" "}
                Remove card
              </button>{" "}
            </>
          ) : (
            <button
              onClick={handleSubmit}
              disabled={!stripe || !elements}
              className="btn btn-cta"
            >
              {" "}
              Add Payment & Continue with Plan
            </button>
          )}
          <p className="mt-2">
            *You’ll be taken to stripe to process your payment
          </p>
        </div>
      </form>
    </Fragment>
  );
};

export default withRouter(CheckOut);
