import React, { Component } from 'react';
import AllRoutes from './router/routes';


class App extends Component {
  render() {
    return (
      <AllRoutes />
    )
  }
}


export default App;