import React, { useEffect, useState } from "react";
import { UtilityFunctions } from "../utils/UtilityFunctions";
import Slider from "../components/slider";
import LegalModal from "../components/legalModal";
import { Link } from "react-router-dom";
import SignInUp from "../components/signInUpModal";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import OTPInput, { ResendOTP } from "otp-input-react";
import { userService } from "../_services/user.service";
import { cookieService } from "_services";
import ToastHeader from "components/toastHeader";
import SuccessToast from "components/successToast";
import ValidationErrorToast from "components/validationErrorToast";
import MainHoc from "./../components/hoc";
import Loader from "common/Loader";
const SignIn = (props) => {
  const emailSignInPlaceholder = STRING_CONSTANTS.LOGIN_PAGE_STRING.SIGNINEMAIL;
  const passwordSignInPlaceholder =
    STRING_CONSTANTS.LOGIN_PAGE_STRING.SIGNINPASSWORD;
  const [emailSignIn, setemailSignIn] = useState("");
  const [passwordSignIn, setpasswordSignIn] = useState("");

  const [showlogin, setShowlogin] = useState(true);
  const [showotp, setShowotp] = useState(false);
  const [otp, setOtp] = useState("");
  const [btnClick, setbtnClick] = useState(false);

  const [enableButton1, setEnableButton1] = useState(true);
  const [enableButton2, setEnableButton2] = useState(true);
  const [enableButton3, setEnableButton3] = useState(true);

  const [activeTab, setActiveTab] = useState("terms");
  const [loading, setLoading] = useState(false);
  const [otpFailed, setOtpFailed] = useState(false);
  const [secureType, setSecureType] = useState(false);
  useEffect(()=>{
    if(otp.length === 6){
      setSecureType(true);
    }
    else{
      setSecureType(false);
    }
  },[otp])

  const checkStep = (step) => {
    if (step == 1) {
      props.history.push("/subscription");
    } else if (step == 2) {
      //props.history.push('/subscription');
      props.history.push("/profileStep1");
    } else {
      props.history.push("/settings/account");
    }
  };

  const resetPwd = {
    pathname: `/forgotpassword`,
    state: { email: emailSignIn },
  };

  const handleLogin = (e) => {
    setbtnClick(true);
    e.preventDefault();

    // clear all Cookies.
    // cookieService.removeAllCookies();
    if (UtilityFunctions.isEmptyOrNullOrUndefined(emailSignIn)) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_toast_email,
        STRING_CONSTANTS.validationString.req_email
      );
    } else if (!UtilityFunctions.validateEmail(emailSignIn)) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_toast_email,
        STRING_CONSTANTS.validationString.req_email_incorrect
      );
    } else if (UtilityFunctions.isEmptyOrNullOrUndefined(passwordSignIn)) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_password,
        STRING_CONSTANTS.validationString.req_password
      );
    } else if (UtilityFunctions.validatePassword(passwordSignIn)) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_password,
        STRING_CONSTANTS.validationString.req_password_incorrect
      );
    } else {
      let params = {
        email: emailSignIn,
        password: passwordSignIn,
      };
      if (emailSignIn === "" || passwordSignIn === "") {
        return;
      } else {
        if (enableButton1) {
          setEnableButton1(false);
          setLoading(true);
          userService
            .login(params)
            .then((res: any) => {
              if (res.data.Token) {
                if (res) {
                  // toast(res.status.message);
                  let response = res.data.Token.access;
                  cookieService.saveCookie(
                    STRING_CONSTANTS.cookieStrings.token,
                    response
                  );
                  cookieService.saveCookie(
                    STRING_CONSTANTS.cookieStrings.emailVerified,
                    res.data.user_details.is_email_verified
                  );
                  // cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, null); // presently we are not setup a user info.
                }
                SuccessToast();
                // if (res.data.user_details.otp_enabled) {
                //     setShowlogin(false);
                //     setShowotp(true);
                // }
                // else {
                //     const { from } = props.location.state || { from: { pathname: '/' } };
                //     props.history.push(from);
                // }
                checkStep(res.data.user_details.web_step);
                //props.history.push('/');
              } else if (res.data.is_email_verified === false) {
                ValidationErrorToast(
                  STRING_CONSTANTS.formLabelString.label_toast_email,
                  res.data.message
                );
              } else {
                SuccessToast();
                setShowlogin(false);
                setShowotp(true);
              }
              setEnableButton1(true);
              setLoading(false);
            })
            .catch((err) => {
              setEnableButton1(true);
              ToastHeader(err);
              setLoading(false);
              return;
            });
        }
      }
    }
  };
  const handleOtp = () => {
    // add otp api here.
    if (UtilityFunctions.isEmptyOrNullOrUndefined(otp)) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_otp,
        STRING_CONSTANTS.validationString.req_otp
      );
    } else if (otp.length !== 6) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_otp,
        STRING_CONSTANTS.validationString.req_valid_otp
      );
    } else {
      const { history } = props;
      const params = {
        otp: otp,
        email: emailSignIn,
      };
      if (otp.length < 6) {
        return;
      } else {
        if (enableButton2) {
          setEnableButton2(false);
          setLoading(true);
          userService
            .verifyOtp(params)
            .then((res) => {
              let response = res.data.Token.access;
              if (response) {
                // toast(res.status.message);
                cookieService.saveCookie(
                  STRING_CONSTANTS.cookieStrings.token,
                  response
                );
                cookieService.saveCookie(
                  STRING_CONSTANTS.cookieStrings.emailVerified,
                  res.data.user_details.is_email_verified
                );
                // cookieService.saveCookie(STRING_CONSTANTS.cookieStrings.user, null); // presently we are not setup a user info.
                setEnableButton2(true);
                SuccessToast();
                checkStep(res.data.user_details.web_step);
                //history.push("/");
              }
              setLoading(false);
            })
            .catch((err) => {
              setEnableButton2(true);
              setOtp('');
              setOtpFailed(true);
              setLoading(false);
              return;
            });
        }
      }
    }
  };
  const sendOtpAgain = () => {
    if (enableButton3) {
      setEnableButton3(false);
      setLoading(true);
      userService
        .sendOtp({
          email: emailSignIn,
        })
        .then(() => {
          setOtp("");
          setEnableButton3(true);
          setLoading(false);
          SuccessToast();
        })
        .catch((err) => {
          setEnableButton3(true);
          ToastHeader(err);
          setLoading(false);
          return;
        });
    }
  };
  const handleSetOtp = (otp) => {
    setOtp(otp);
    setOtpFailed(false);
  };

  return (
    <MainHoc>
      {loading && <Loader />}
      {showlogin && (
        <div className="col-md-6 left-panel">
          <div className="logo-wrap">
            <img src={UtilityFunctions.getImageURL() + "logo.svg"} alt="" />
          </div>
          <div className="left-wrap w-100">
            <h4>Welcome Back.</h4>
            <h2>
              <span className="fw-normal">Welcome to</span>{" "}
              <span className="primary-blue fw-bold">Mind</span>{" "}
              <span className="purple fw-bold">Geni!</span>
            </h2>
            <h5>
              Not a Member?{" "}
              <Link to={"signup"} className="light-blue fw-bold">
                Sign Up
              </Link>
            </h5>
            <SignInUp
              ePlaceholder={emailSignInPlaceholder}
              pPlaceholder={passwordSignInPlaceholder}
              email={emailSignIn}
              setemail={setemailSignIn}
              password={passwordSignIn}
              setpassword={setpasswordSignIn}
              btnClick={btnClick}
              setbtnClick={setbtnClick}
            />
            <Link className="text-white" to={resetPwd}>
              <small className="d-flex justify-content-end">
                Forgot Password?
              </small>
            </Link>

            <p className="mt-4 mb-5">
              By continuing you agree to our
              <span
                className="text-white pointer"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={() => setActiveTab("terms")}
              >
                {" "}
                Terms{" "}
              </span>
              <span className="text-white">&</span>
              <span
                className="text-white pointer"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={() => setActiveTab("privacy")}
              >
                {" "}
                Privacy{" "}
              </span>
            </p>
            <LegalModal activeTabState={activeTab} />
            <div className="d-grid text-center">
              <button className="btn btn-cta" onClick={handleLogin}>
                {" "}
                Login to MindGeni
              </button>
            </div>
          </div>
        </div>
      )}
      {showotp && (
        <div className="col-md-6 left-panel">
          <div className="logo-wrap">
            <img src={UtilityFunctions.getImageURL() + "logo.svg"} alt="" />
          </div>
          <div className="left-wrap w-100">
            <h4
              onClick={() => {
                setShowotp(false);
                setShowlogin(true);
                setOtp("");
              }}
              className="pointer"
            >
              <img
                src={UtilityFunctions.getImageURL() + "arrow-left.svg"}
                alt="left Arrow"
              />
              Login Verification
              {/* <Link to={'/signin'} className='light-blue'>Login Verification</Link> */}
            </h4>
            <h2>
              We’ve sent a verification <br />
              code to your email...{" "}
            </h2>
            <h5 className="mb-5">
              Check your email & enter the code sent to {emailSignIn}
            </h5>
            <OTPInput
              value={Number(otp)}
              placeholder="------"
              onChange={handleSetOtp}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              secure = {secureType}
              inputClassName ={
                secureType? "dots":""
              }
            />

            {/* <OtpInput
              value={otp}
              onChange={handleSetOtp}
              numInputs={6}
              separator={<span style={{ width: "8px" }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              inputStyle={{
                border: "1px solid transparent",
                borderRadius: "8px",
                width: "54px",
                height: "54px",
                fontSize: "12px",
                color: "#000",
                fontWeight: "400",
                caretColor: "blue"
              }}
              focusStyle={{
                border: "1px solid #CFD3DB",
                outline: "none"
              }}
            /> */}
           

            <h5 className="my-5 text-style">
              Didn’t get a code?{" "}
              <span
                className="text-white fw-bold pointer"
                onClick={sendOtpAgain}
              >
                Send Again
              </span>
            </h5>

            <div className="d-grid text-center">
              <button className="btn btn-cta" onClick={() => handleOtp()}>
                {" "}
                Verify Login
              </button>
              {otpFailed ?
              <div className='mt-3'>
                  <p className='otp-error'>
                            <img alt="" className="otp-toast-icon" src={UtilityFunctions.getImageURL() + "error-message-icon.svg"}/>
                                <span className='error-message-field-otp'>Verification failed . Please enter a valid otp</span></p></div> : ""}
            </div>
          </div>
        </div>
      )}
      {/* // https://www.npmjs.com/package/otp-input-react */}
      <Slider />
    </MainHoc>
  );
};

export default SignIn;
