import React, { Fragment, useEffect, useState } from 'react';

import ProgressBar from "@ramonak/react-progress-bar";
import { UtilityFunctions } from 'utils/UtilityFunctions';
import { STRING_CONSTANTS } from 'utils/constants/stringConstants';
import ValidationErrorToast from 'components/validationErrorToast';
import { userService } from '_services';
import SuccessToast from 'components/successToast';
import ToastHeader from 'components/toastHeader';
import Welcome from './profile/welcome';
import Loader from 'common/Loader';

const Step1 = (props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [progress, setProgress] = useState(33);
    const [enableButton1, setEnableButton1] = useState(true);
    const [btnClick, setbtnClick] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        setLoading(true);
        userService.viewProfile()
            .then((response) => {
                if(response){
                setFirstName(response.user.first_name ? response.user.first_name : "");
                setLastName(response.user.last_name ? response.user.last_name : "");
                }
            })
            .catch((err)=>{
                ToastHeader(err);
            })
        setLoading(false);
    },[])

    const updateStep1 = () => {
        setbtnClick(true)
        if (UtilityFunctions.isEmptyOrNullOrUndefined(firstName)) {
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_first_name, STRING_CONSTANTS.validationString.req_first_name);
        }
        else if (UtilityFunctions.isEmptyOrNullOrUndefined(lastName)) {
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_last_name, STRING_CONSTANTS.validationString.req_last_name);
        }
        else {
            const params = {
                first_name: firstName,
                last_name: lastName
            }
            if (firstName !== '' && lastName !== '' && enableButton1) {
                setEnableButton1(false);
                userService.updateProfile(params)
                    .then((response) => {
                        SuccessToast();
                        setProgress(66);
                        setbtnClick(false);
                        setEnableButton1(true);
                        setbtnClick(false);
                        props.history.push('/profileStep2');
                        return
                    })
                    .catch((err) => {
                        setEnableButton1(true);
                        setbtnClick(false);
                        ToastHeader(err);
                    });

            }
        }
    }
    
    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className='container-fluid p-0'>
                <div className="row gx-0 bg-blue vh-100">
                    <div className="col-sm-6 left-panel">
                        <div className="logo-wrap">
                            <img src={UtilityFunctions.getImageURL() + 'logo.svg'} alt="" />
                        </div>
        <Fragment>
            <div className="left-wrap w-100 outerspace">
                <h4 className='m-0'>Create Profile</h4>

                <ProgressBar
                    baseBgColor="rgb(255 255 255 / 12%)"
                    bgColor="#FFF"
                    height="4px"
                    width="160px"
                    margin="16px 0px 24px 0px"
                    completed={progress}
                    customLabel="&nbsp;"
                />

                <h2 className='heading-wrap'>Lets Create your Profile.</h2>
                <h5 className='heading-wrap mb-4'>First some basic details.</h5>

                <div className="d-grid">
                    <div 
                        // className="form-floating-custom"
                        className={`form-floating-custom ${!btnClick ? "" : firstName.length > 0 ? "" : "form-floating-custom red-bg"}`}
                    >
                        <input type="text" placeholder="Add your First Name" value={firstName} onChange={(e) => { setFirstName(e.target.value); setbtnClick(false) }} className={`form-floating-custom ${!btnClick ? "" : firstName.length > 0 ? "" : "is-invalid"}`} />
                        <label>First Name</label>
                    </div>
                    {!btnClick ? "" : firstName.length > 0 ? "" : <div className='display-error-message'><img alt="" className="toast-icon" src={UtilityFunctions.getImageURL() + "error-message-icon.svg"} /><span className='error-message-field'>{STRING_CONSTANTS.validationString.req_first_name}</span></div>}
                    <div className='mb-3'></div>
                    <div 
                        // className="form-floating-custom"
                        className={`form-floating-custom ${!btnClick ? "" : firstName.length > 0 ? "" : "form-floating-custom red-bg"}`}

                    >
                        <input type="text" placeholder="Add your Last Name" value={lastName} onChange={(e) => { setLastName(e.target.value); setbtnClick(false) }} className={`form-floating-custom ${!btnClick ? "" : lastName.length > 0 ? "" : "is-invalid"}`} />
                        <label>Last Name</label>
                    </div>
                    {!btnClick ? "" : lastName.length > 0 ? "" : <div className='display-error-message'><img alt="" className="toast-icon" src={UtilityFunctions.getImageURL() + "error-message-icon.svg"} /><span className='error-message-field'>{STRING_CONSTANTS.validationString.req_last_name}</span></div>}
                </div>
            </div>
        </Fragment>
                        <div className="d-grid text-center w-100">
                            {<button className='btn btn-cta  mt-4' onClick={() => updateStep1()}>Next Step</button>}
                        </div>
        </div>
        <Welcome />
        </div>
        </div>
        </React.Fragment>
    )
}
export default Step1;
