export const UtilityFunctions = {
    getImageURL,
    validateEmail,
    validatePhone,
    isEmptyOrNullOrUndefined,
    API_URL,
    validatePassword,
    trimPhoneNumberSpaces,
    validateAbnNumber
};

function getImageURL(): string {
    return process.env.REACT_APP_LIVE_IMAGEURL;
}

function API_URL() {
    return process.env.REACT_APP_LIVE_APIURL
}


function validateEmail(value: any) {
    return (/^([A-Za-z_\-0-9\.])+\@([A-Za-z_\-\.])+\.([A-Za-z]{2,4})$/).test(value);
}

function validatePhone(value: any) {
    //return (/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(value); //9999999999
    return (trimPhoneNumberSpaces(value).length >= 12);
}
function validateAbnNumber(value: any) {
    //return (/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(value); //9999999999
    return (trimPhoneNumberSpaces(value).length === 11);
}
function isEmptyOrNullOrUndefined(str: string) {
    str = (str === undefined || str == null || str === "0") ? "" : str.toString().trim();
    if (str === "")
        return true;
    return false;
}
function validatePassword(str: string) {
    //return !(/^(?=.*\d).{8,}$/.test(str));
    //return !(/^(?=.*\d)(?=.*[A-Za-z]).{8,}$/).test(str);
    return !(/^(?=.*\d).{8,}$/).test(str);
}
function trimPhoneNumberSpaces(value: any) {
    return value.replace(/ /g, '');
}

export const getCurrentYear = () => {
    return new Date().getFullYear();
};
export const blobToFile = (theBlob: Blob, name = 'filename.png'): File => {
    return new File([theBlob], name, { lastModified: new Date().getTime(), type: theBlob.type })
}

export const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}
export const tabData = [
    { value: "My Account", state: "active", show: "show", info: "a", url: "/account" },
    { value: "Business Details", state: "", show: "", info: "b", url: "/businessDetails" },
    { value: "Password & Security", state: "", show: "", info: "c", url: "/passwordSecurity" },
    { value: "Subscription & Payment", state: "", show: "", info: "d", url: "/subscription" },
    { value: "Contact Us", state: "", show: "", info: "e", url: "/contact" },
    { value: "More Settings", state: "", show: "", info: "aa", url: "/setting" }

]
export const planImages = [{
    leftImage: 'leaf-y-left.svg',
    rightImage: 'leaf-y-right.svg'
},
{
    leftImage: 'leaf-g-left.svg',
    rightImage: 'leaf-g-right.svg'
},
{
    leftImage: 'leaf-r-left.svg',
    rightImage: 'leaf-r-right.svg'
},
{
    leftImage: 'leaf-r-left.svg',
    rightImage: 'leaf-r-right.svg'
}]