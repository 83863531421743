import React, { Fragment, useEffect, useState } from 'react';
import LeftAside from 'components/leftAside';
import Account from 'components/Dashboard/account';
import BusinessDetails from 'components/Dashboard/businessDetails';
import PasswordSecurity from 'components/Dashboard/passwordSecurity';
import Subscription from 'components/Dashboard/subscription';
import Contact from 'components/Dashboard/contact';
import MoreSettings from 'components/Dashboard/moreSettings';
import { UtilityFunctions } from 'utils/UtilityFunctions';
import SuccessToast from 'components/successToast';
import ToastHeader from 'components/toastHeader';
import { userService } from '_services';
import ValidationErrorToast from 'components/validationErrorToast';
import { STRING_CONSTANTS } from 'utils/constants/stringConstants';
import heic2any from "heic2any";
import ConfirmPwd from 'components/confirmPwd';
import Loader from 'common/Loader';

const Settings = (props) => {
    const url = props?.match?.params?.id;
    const [checkAccess, setCheckAccess] = useState(true);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [abnNumber, setAbnNumber] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [addState, setAddState] = useState('');
    const [code, setCode] = useState('');
    const [profileImg, setProfileImg] = useState(null);
    const [showProfileImg, setShowProfileImg] = useState(null);
    const [bussinessLogo, setBussinessLogo] = useState(null);
    const [showBussinessLogo, setShowBussinessLogo] = useState(null);
    const [checked, setChecked] = useState(false);
    const [copyEmail, setCopyEmail] = useState('');
    const [copyNumber, setCopyNumber] = useState('');
    const [isModalOpen, setModalStatus] = useState(false);
    const [pwd, setPwd] = useState('');
    const [enableButton1, setEnableButton1] = useState(true);
    const [enableButton2, setEnableButton2] = useState(true);
    const [imgUploaded, setImgUploaded] = useState(false);
    const [imgBusinessUploaded, setImgBusinessUploaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [firstTimeUploaded, setFirstTimeUploaded] = useState(false);
    const [firstTimeLogoUploaded, setFirstTimeLogoUploaded] = useState(false);
    const [copyAbnNumber, setCopyAbnNumber] = useState('');
    const [btnClick, setBtnClick] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [newImgUploaded, setNewImgUploaded] = useState(false);
    const [refreshAccount, setRefreshAccount] = useState(false);

    const blobToFile = (theBlob: Blob): File => {
        return new File([theBlob], 'filename.png', { lastModified: new Date().getTime(), type: theBlob.type })
    }


    useEffect(() => {
        setLoading(true);
        userService.viewProfile()
            .then((response) => {
                setEmail(response.user.email);
                setCopyEmail(response.user.email);
                setFirstName(response.user.first_name);
                setLastName(response.user.last_name);
                setProfileImg(response.user.profile_image);
                setMobileNumber((response.user.mobile_number));
                setCopyNumber((response.user.mobile_number));
                setAbnNumber(response.clinic_details.clinic_abn === null ? '' : response.clinic_details.clinic_abn);
                setCopyAbnNumber(response.clinic_details.clinic_abn === null ? '' : response.clinic_details.clinic_abn);
                setAddress(response.clinic_details.clinic_address_line_one === null ? '' : response.clinic_details.clinic_address_line_one);
                setBussinessLogo(response.clinic_details.clinic_logo);
                setCity(response.clinic_details.clinic_city === null ? '' : response.clinic_details.clinic_city);
                setAddState(response.clinic_details.clinic_state === null ? '' : response.clinic_details.clinic_state);
                setCode(response.clinic_details.clinic_post_code === null ? '' : response.clinic_details.clinic_post_code);
                setChecked(response.user.otp_enabled);
                setLoading(false);
                setEmailVerified(response.user.is_email_verified);
            })
            .catch((err) => {
                setLoading(false);
                setCheckAccess(false);
                ToastHeader(err);
            });
    }, [refreshAccount]);
    const dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
    const updatedDetailsOfAccount = () => {
        const formData = new FormData();
        if (email !== copyEmail) {
            formData.append('password', pwd);
            formData.append('email', email);
        }
        // if (profileImg !== null) {
        //     formData.append('profile_image', profileImg);
        // }
        if (newImgUploaded){
        if (imgUploaded) {
            formData.append('profile_image', blobToFile(profileImg));
        }
        else if (profileImg) {
            formData.append('profile_image', dataURLtoFile(profileImg, 'filename.png'));
        }
    }
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        setLoading(true);
        if (firstName !== '' && lastName !== '' && email !== '') {
            if (enableButton1) {
                setEnableButton1(false);
                userService.updateProfile(formData)
                    .then((res) => {
                        if (email !== copyEmail) {
                            setCopyEmail(email);
                            setPwd('');
                        }
                        setEnableButton1(true);
                        SuccessToast();
                        setImgUploaded(false);
                        setLoading(false);
                        setNewImgUploaded(false);
                    })
                    .catch((err) => {
                        setEnableButton1(true);
                        setEmail(copyEmail)
                        ToastHeader(err);
                        setLoading(false);
                        setImgUploaded(false);
                        setNewImgUploaded(false);
                    });
            }
        }
        else {
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_field_req, STRING_CONSTANTS.validationString.req_fields);
            setEnableButton1(true);
            setLoading(false);
        }
    }
    const updateAccountDetails = () => {
        if (email !== copyEmail && email !== '') {
            if (!UtilityFunctions.validateEmail(email)) {
                ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_toast_email, STRING_CONSTANTS.validationString.req_email_incorrect);
            }
            else {
                setPwd('');
                setModalStatus(true);
            }
        }
        else {
            updatedDetailsOfAccount()
        }
    }

    const updateDetails = () => {
        setBtnClick(true);
        const bussinessFormData = new FormData();
        // if (showBussinessLogo !== null) {
        //     bussinessFormData.append('clinic_logo', bussinessLogo);
        // }
        if (imgBusinessUploaded) {
            bussinessFormData.append('clinic_logo', bussinessLogo);
        }
        const abnParams = {
            abn: abnNumber
        }
        bussinessFormData.append('clinic_address_line_one', address);
        bussinessFormData.append('mobile_number', mobileNumber);
        bussinessFormData.append('clinic_city', city);
        bussinessFormData.append('clinic_abn', abnNumber);
        //setModalStatus(true);
        if (mobileNumber !== copyNumber) {
            bussinessFormData.append('password', pwd);
        }
        setLoading(true);
        if (abnNumber !== '') {
            userService.abnNumberLookUp(abnParams)
                .then((response) => {
                    if (enableButton2) {
                        setEnableButton2(false);
                        if (response.data.data.AbnStatus === "Cancelled") {
                            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_abn_number, STRING_CONSTANTS.validationString.req_abn_number_inactive);
                            setAbnNumber(copyAbnNumber)
                            setEnableButton2(true);
                        }
                        else if (response.data.data.AbnStatus === "") {
                            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_abn_number, response.data.data.Message);
                            setAbnNumber(copyAbnNumber)
                            setEnableButton2(true);
                        }
                        else {
                            if (address !== '' && mobileNumber !== '' && city !== '' && addState !== '' && code !== '') {
                                setAddState(response.data.data.AddressState);
                                setCode(response.data.data.AddressPostcode);
                                bussinessFormData.append('clinic_state', response.data.data.AddressState);
                                bussinessFormData.append('clinic_post_code', response.data.data.AddressPostcode);
                                userService.updateProfile(bussinessFormData)
                                    .then((res) => {
                                        if (mobileNumber !== copyNumber) {
                                            setCopyNumber(mobileNumber);
                                            setPwd('');
                                        }
                                        setEnableButton2(true);
                                        setCopyAbnNumber(abnNumber);
                                        SuccessToast();

                                    })
                                    .catch((err) => {
                                        setEnableButton2(true);
                                        setMobileNumber(copyNumber);
                                        ToastHeader(err);

                                    });
                            }
                            else {
                                ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_field_req, STRING_CONSTANTS.validationString.req_fields);
                                setEnableButton2(true);
                            }
                        }
                    }
                    setLoading(false);
                    setImgBusinessUploaded(false);
                })
                .catch((err) => {
                    setLoading(false);
                    setImgBusinessUploaded(false);
                    ToastHeader(err);
                })

        }
        setLoading(false);
    }
    const updateBussinessDetails = () => {
        if (mobileNumber !== copyNumber && UtilityFunctions.validatePhone(mobileNumber)) {
            setPwd('');
            setModalStatus(true);
        }

        else {
            updateDetails();
        }


    }
    const closeButton = () => {
        setMobileNumber(copyNumber);
        setEmail(copyEmail);
        setModalStatus(false);
    }

    const confirmPwd = () => {

        setModalStatus(false);
        if (mobileNumber !== copyNumber) {
            updateDetails()
        }
        if (email !== copyEmail) {
            updatedDetailsOfAccount()
        }

    }

    return (
        <LeftAside activeMenu={'settings'} profileImg={profileImg} showProfileImg={showProfileImg} firstTimeUploaded={firstTimeUploaded} refreshAccount={refreshAccount} setRefreshAccount={setRefreshAccount}>
            {loading && <Loader />}
            {checkAccess ? "" :
                <div className='not-found-wrap'>
                    <img className='mx-auto d-block' src={UtilityFunctions.getImageURL() + 'unauthorised-logo.svg'} alt="" />
                    <br />
                    <h6 className='text-center'>Content has been hidden 👀</h6>
                    <div className='info-message'>Sorry! Seems like you don’t have the correct
                        privileges to view this content</div>
                </div>
            }
            <div className={checkAccess ? "" : "admin-unauthorized-wrap"}>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active">
                        {url === 'account' && <Fragment>
                            <Account updateAccountDetails={updateAccountDetails} email={email} setEmail={setEmail} firstName={firstName} setFirstName={setFirstName}
                                lastName={lastName} setLastName={setLastName} showProfileImg={showProfileImg} profileImg={profileImg}
                                checkAccess={checkAccess} setProfileImg={setProfileImg} setImgUploaded={setImgUploaded} imgUploaded={imgUploaded} firstTimeUploaded={firstTimeUploaded}
                                setFirstTimeUploaded={setFirstTimeUploaded} emailVerified={emailVerified} setNewImgUploaded={setNewImgUploaded} newImgUploaded={newImgUploaded}/>
                        </Fragment>}
                        {url === 'businessDetails' && <Fragment>
                            <BusinessDetails abnNumber={abnNumber} setAbnNumber={setAbnNumber} mobileNumber={mobileNumber} setMobileNumber={setMobileNumber}
                                address={address} setAddress={setAddress} addState={addState} setAddState={setAddState} checkAccess={checkAccess} bussinessLogo={bussinessLogo}
                                city={city} code={code} updateBussinessDetails={updateBussinessDetails} setCity={setCity}
                                setBussinessLogo={setBussinessLogo} setImgBusinessUploaded={setImgBusinessUploaded} imgBusinessUploaded={imgBusinessUploaded}
                                setFirstTimeLogoUploaded={setFirstTimeLogoUploaded} firstTimeLogoUploaded={firstTimeLogoUploaded} btnClick={btnClick} setBtnClick={setBtnClick}
                            />
                        </Fragment>}
                        {url === 'passwordSecurity' && <Fragment>
                            <PasswordSecurity checked={checked} setChecked={setChecked} checkAccess={checkAccess} />
                        </Fragment>}
                        {url === 'subscription' && <Fragment>
                            <Subscription />
                        </Fragment>}
                        {url === 'contact' && <Fragment>
                            <Contact checkAccess={checkAccess} />
                        </Fragment>}
                        {url === 'setting' && <Fragment>
                            <MoreSettings />
                        </Fragment>}
                    </div>
                </div >
            </div >
            <ConfirmPwd isModalOpen={isModalOpen} setModalStatus={setModalStatus} confirmPwd={confirmPwd} pwd={pwd} setPwd={setPwd} closeButton={closeButton} />
        </LeftAside >

    )
}
export default Settings;