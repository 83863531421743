import React, { Ref , useState } from "react";
import Webcam from "react-webcam";

const videoConstraints = {
    height: 720,
    width: 1280,
    facingMode: "user"
};

interface WebcamCaptureProps {
    height?: number | any;
    width?: number | any;
    onUserMedia?: (stream: MediaStream) => void;
    onUserMediaError?: (error: string | any) => void;
}

export default React.forwardRef((props: WebcamCaptureProps, ref: Ref<HTMLInputElement | any>) => {
    return <Webcam
        audio={false}
        mirrored={true}
        ref={ref}
        screenshotFormat="image/jpeg"
        height={props?.height || 300}
        width={props?.width || 300}
        forceScreenshotSourceSize
        videoConstraints={videoConstraints}
        onUserMedia={props?.onUserMedia}
        onUserMediaError={props?.onUserMediaError}
        screenshotQuality={1}
        style={{ objectFit: "cover" }}
    />;
})