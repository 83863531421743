import React from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckOut from "./checkout";
import "./stripeCustom.css";


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Stripe = (props) => (
  <Elements stripe={stripePromise} >
    <CheckOut isDashboard={props.isDashboard} setIsDashboard={props.setIsDashboard} getPlan={props.getPlan} isDashboardCheck={props.isDashboardCheck}/>
  </Elements>
);

export default Stripe;