import React from 'react';
import Wrapper from '../common/Wrapper';
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { Route, Link } from 'react-router-dom';
import { cookieService } from '_services';

class NotFound extends React.Component<any, any> {

  handleClose = () => {
    cookieService.removeAllCookies();
    const { history } = this.props;
    history.push("/signin")
  }
  render() {
    return (
      <Wrapper>
        <div className="Page_notFound App">
          <article>
            <h2>{STRING_CONSTANTS.page404.page_not_found}</h2>
            <div className="text-center">
              <button className="ls-btn ls-sd" onClick={() => { this.handleClose() }}>  {STRING_CONSTANTS.page404.back_to_home}</button>
            </div>
          </article>
        </div>
      </Wrapper>
    );
  }
}

export default NotFound;
