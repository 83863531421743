import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { UtilityFunctions, tabData } from "../utils/UtilityFunctions";
import Logout from "components/logout";
import FaqModal from "components/faq";

const LeftAside = (props) => {
  const { children, history } = props;
  const url = props?.match?.params?.id;
  const [activeMenuItem, setActiveMenuItem] = useState(false);
  const [tabbedData, setTabbedData] = useState(`/${url}`);

  const handleMenu = () => {
    setActiveMenuItem(!activeMenuItem);
  };
  const updateTabs = (e) => {
    setTabbedData(e.url);
    // setNewPwd('');
    // setCurrentPwd('');
    if(e.url === '/account'){
      props.setRefreshAccount(!props.refreshAccount);
    }
    history?.push(`/settings${e.url}`);
  };

  return (
    <div className="wrapper">
      <div className={`sidebar ${activeMenuItem ? "active" : ""}`}>
        <div className="logo">
          <img
            src={UtilityFunctions.getImageURL() + "logo-dashboard.svg"}
            alt=""
          />
        </div>
        <div className="menu">
          <div
            className="icon-menu"
            data-bs-toggle="modal"
            data-bs-target="#faqmodal"
          >
            <img
              src={UtilityFunctions.getImageURL() + "faq.svg"}
              alt=""
              className="img-container"
            />
          </div>
          <div
            className={`icon-menu ${
              props.activeMenu === "settings" ? "active" : ""
            } `}
            onClick={() => {
              setTabbedData("/account");
              history?.push("/settings/account");
            }}
          >
            <img
              src={UtilityFunctions.getImageURL() + "setting-icon.svg"}
              alt=""
              className="img-container"
            />
          </div>
          <div
            className="icon-menu"
            data-bs-toggle="modal"
            data-bs-target="#logout"
          >
            <img
              src={UtilityFunctions.getImageURL() + "logout-icon.svg"}
              alt=""
              className="img-container"
            />
          </div>
        </div>
      </div>
      <div className={`main ${activeMenuItem ? "active" : ""}`}>
        <div className="sticky-top">
          <div className="row">
            <div className="col-md-12 position-relative">
              <h4>My Profile & Settings</h4>
              <img
                alt=""
                className="online-red-icon"
                src={UtilityFunctions.getImageURL() + "red-icon.svg"}
              />
              {/* {props.profileImg === null ? <img alt="" className="profile-image-show" src={UtilityFunctions.getImageURL() + 'bussiness-logo.svg'} /> : props.showProfileImg === null ? <img alt="" className="profile-image-show" src={props.profileImg} /> : <img alt="" className="profile-image-show" src={props.showProfileImg} />}  */}
              {/* { console.log(props.firstTimeUploaded , "first",  props.profileImg , "profile")
              } */}
              <img
                alt=""
                className="profile-image-show"
                src={
                  props.profileImg==null? UtilityFunctions.getImageURL() + "logo.svg":
                  props.firstTimeUploaded
                    ? URL.createObjectURL(props.profileImg)
                    : props.profileImg
                }
              />
              <div
                className={`mobile_menu ${activeMenuItem ? "active" : ""}`}
                onClick={() => handleMenu()}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
          <div className="tabs-responsive">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              {tabData.map((i, idx) => {
                return (
                  <li
                    key={idx + "newe"}
                    className="nav-item"
                    role="presentation"
                  >
                    <span
                      className={`nav-item  ${
                        i.url === tabbedData ? "active" : ""
                      }`}
                      onClick={() => updateTabs(i)}
                    >
                      {i.value}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="topbar">{children}</div>
      </div>
      <Logout />
      <FaqModal />
    </div>
  );
};
export default withRouter(LeftAside);
