import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { cookieService, userService } from '../_services';
import { STRING_CONSTANTS } from '../utils/constants/stringConstants';

class Header extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }
    // componentDidMount() {
    //     userService.getProfile().then(
    //         data => {
    //             // console.log('data', data);
    //             this.setState({ data: data })
    //         },
    //         error => {
    //             console.log(error);
    //             // this.setState({ error })
    //         }
    //     )
    // }
    logout = () => {
        cookieService.removeAllCookies();
        this.props.history.push(STRING_CONSTANTS.navigationString.signin);
    }
    render() {
        // console.log('this.props.data', this.props);
        return (
            <header style={{ height: 82 }}>
                <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/">{STRING_CONSTANTS.formLabelString.label_logo}</Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <ul className="navbar-nav me-auto mb-2 mb-md-0">
                                {STRING_CONSTANTS.menus.map((item) => {
                                    return <li className="nav-item" key={item.id}>
                                        <Link className="nav-link" to={item.link}>{item.name}</Link>
                                    </li>
                                })}
                            </ul>
                            <form className="d-flex">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" to="#" id="dropdown05" data-bs-toggle="dropdown" aria-expanded="false">{this.state.data.firstName}</Link>
                                        <ul className="dropdown-menu" aria-labelledby="dropdown05">
                                            <li style={{ padding: '0px 12px', cursor: 'pointer' }}><span onClick={() => { this.logout() }}>{STRING_CONSTANTS.formLabelString.label_logout}</span></li>
                                        </ul>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </nav>

            </header>
        );
    }
}

export default withRouter(Header);