import React, { Component, Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";

/**
 * Wrapper App container.
 */
class Wrapper extends Component {
    render() {
        const { children } = this.props;
        return (
            <Fragment>
                <Header />
                <section className="container-fluid">
                    {children}
                </section>
                <Footer />
            </Fragment>
        );
    }
}

export default Wrapper;
