import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './router';
import Home from '../pages/home';
import SignIn from '../pages/signIn';
import SignUp from '../pages/signUp';
import ForgotPassword from '../pages/forgotPassword';
import ResetPassword from '../pages/resetPassword';
import Subscription from '../pages/subscription';
import { ToastContainer } from 'react-toastify';
import Test from '../test';
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import Settings from '../pages/settings';
import Step1 from 'pages/profileStepOne';
import Step2 from 'pages/profileStepTwo';
import Step3 from 'pages/profileStepThree';
import UnauthRouter from './unauthRouter';
import NotFound from 'pages/notFound';
export default class AllRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          style={{ zIndex: 9999999 }}
          hideProgressBar
        // autoClose={false}

        />
        <Router>
          <Switch>
            <UnauthRouter exact path={STRING_CONSTANTS.navigationString.signup} component={SignUp} />
            <UnauthRouter exact path={STRING_CONSTANTS.navigationString.signin} component={SignIn} />
            <UnauthRouter exact path={STRING_CONSTANTS.navigationString.forgotpwd} component={ForgotPassword} />
            <Route exact path={STRING_CONSTANTS.navigationString.test} component={Test} />

            <PrivateRoute exact path={STRING_CONSTANTS.navigationString.Subscription} component={Subscription} />
            <PrivateRoute exact path={STRING_CONSTANTS.navigationString.profileStep1} component={Step1} />
            <PrivateRoute exact path={STRING_CONSTANTS.navigationString.profileStep2} component={Step2} />
            <PrivateRoute exact path={STRING_CONSTANTS.navigationString.profileStep3} component={Step3} />
            <UnauthRouter exact path={`${STRING_CONSTANTS.navigationString.resetpwd}/:uid/:token`} component={ResetPassword} />

            <PrivateRoute exact path={'/settings/:id'} component={Settings} />
            <PrivateRoute exact path={'/*'} component={NotFound} />

          </Switch>
        </Router>
      </React.Fragment>
    )
  }
}