import React, { useState } from "react";
import { Link } from "react-router-dom";
import { UtilityFunctions } from "utils/UtilityFunctions";
import ToastHeader from "components/toastHeader";
import SuccessToast from "components/successToast";
import { userService } from "_services";
import Loader from "common/Loader";
import { STRING_CONSTANTS } from "utils/constants/stringConstants";
import ValidationErrorToast from "components/validationErrorToast";

const Contact = (props) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnClick, setBtnClick] = useState(false);

  const saveMessage = () => {
    setBtnClick(true);
    if (UtilityFunctions.isEmptyOrNullOrUndefined(subject)) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_subject,
        STRING_CONSTANTS.validationString.req_subject
      );
    } else if (UtilityFunctions.isEmptyOrNullOrUndefined(message)) {
      ValidationErrorToast(
        STRING_CONSTANTS.formLabelString.label_message,
        STRING_CONSTANTS.validationString.req_message
      );
    } else {
      const messageParams = {
        subject: subject,
        description: message,
      };
      setLoading(true);
      userService
        .sendMessage(messageParams)
        .then((res) => {
          SuccessToast();
          setMessage("");
          setSubject("");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          ToastHeader(err);
        });
      setBtnClick(false);
    }
    // API integration pending
  };
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };

  return (
    <div className="admin-wrap">
      {loading && <Loader />}
      <div className="justify-content-center details-container mt-0">
        <div className="d-grid position-relative">
          <div className="row">
            <div className="col-sm-6">
              <div className="mb-4"></div>
              <h4 className="mb-0">Get in touch</h4>
              <label className="contact-text-first mt-3 pt-3">Contact</label>
              <br />
              <label className="mt-2 pt-1">
                <p className="mb-0">
                  <a href="mailto:mindgeni@customerservice.com">
                    <span className="mail-to">
                      mindgeni@customerservice.com
                    </span>
                  </a>
                </p>
                <p>
                  <a
                    className="contact-text-first"
                    style={{ opacity: 0.88 }}
                    href="tel:+61 404 040 404"
                  >
                    +61 404 040 404
                  </a>
                </p>
              </label>
            </div>
            <div className="col-sm-6">
              <div className="contact-us-image">
                <img
                  src={UtilityFunctions.getImageURL() + "contact-us.svg"}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="mt-5 contact-text-second">
            Ask us anything or just say hi
          </div>
          <div className="v-all-form">
            <div className="v-mid-form">
              <div 
                // className="form-floating-custom-profile mb-4 pb-1 mt-3"
                className={
                    subject.length > 0 || !btnClick
                      ? "form-floating-custom-profile mb-4 pb-1 mt-3"
                      : "form-floating-custom-profile mb-4 pb-1 mt-3 red-bg red-bg-xs"
                  }
                >
                <input
                  type="text"
                  className={
                    subject.length > 0 || !btnClick
                      ? ""
                      : "is-invalid-contact is-invalid"
                  }
                  placeholder="Example"
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value);
                    setBtnClick(false);
                  }}
                  disabled={!props.checkAccess}
                />
                <label>Subject</label>
              </div>
              <div 
            //   className="form-floating-custom-profile mb-4 pb-1 mt-3"
              className={
                    message.length > 0 || !btnClick
                      ? "form-floating-custom-profile mb-4 pb-1 mt-3"
                      : "form-floating-custom-profile mb-4 pb-1 mt-3 red-bg red-bg-lg"
                  }
              >
                <textarea
                  name=""
                  id=""
                  className={
                    message.length > 0 || !btnClick
                      ? ""
                      : "is-invalid-contact is-invalid"
                  }
                  placeholder="Write your message"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    setBtnClick(false);
                  }}
                  disabled={!props.checkAccess}
                ></textarea>
                <label className="textarea-label">Message</label>
              </div>
              <div className="d-grid text-center mt-5 mb-5">
                <button
                  className="btn btn-cta btn-color"
                  onClick={saveMessage}
                  disabled={!props.checkAccess}
                >
                  {" "}
                  Send Message{" "}
                </button>
              </div>
            </div>
            <div className="social-icons">
              <div className="row">
                <div className="col icon-center">
                  <Link to="https://www.facebook.com/MindGeni">
                    <img
                      src={UtilityFunctions.getImageURL() + "facebook-icon.svg"}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="col icon-center">
                  <Link to="/">
                    <img
                      src={UtilityFunctions.getImageURL() + "twitter.svg"}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="col icon-center">
                  <Link to="https://www.instagram.com/mind_geni/">
                    <img
                      src={UtilityFunctions.getImageURL() + "instagram.svg"}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
