import React, { useState } from 'react';
import { UtilityFunctions } from 'utils/UtilityFunctions';
import { STRING_CONSTANTS } from 'utils/constants/stringConstants';

export const SignInUp = (props) => {
    const [passwordType, setPasswordType] = useState("password");
    const store = !UtilityFunctions.validateEmail(props.email);
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    return (
        <div className="d-grid">
            <div className={`form-floating-custom ${!props.btnClick ? "" : store === false ? "" : "red-bg"}`}   >
                <input type="email" className={`form-floating-custom ${!props.btnClick ? "" : store === false ? "" : "is-invalid"}`} placeholder={props.ePlaceholder} value={props.email} onChange={(e) => { props.setemail(e.target.value); props.setbtnClick(false); }} />
                <label>Email</label>
            </div>
            {!props.btnClick ? "" : store === false ? "" : props.email.length > 0 ? <div className='display-error-message'><img alt="" className="toast-icon" src={UtilityFunctions.getImageURL() + "error-message-icon.svg"} /><span className='error-message-field'>{STRING_CONSTANTS.validationString.req_email_incorrect}</span></div> : <div className='display-error-message'><img alt="" className="toast-icon" src={UtilityFunctions.getImageURL() + "error-message-icon.svg"} /><span className='error-message-field'>{STRING_CONSTANTS.validationString.req_email}</span></div>}

            <div className='mb-4'></div>

            <div className={`form-floating-custom ${!props.btnClick ? "" : store === false ? "" : "red-bg"}`}>
                <input type={passwordType} className={`form-floating-custom ${!props.btnClick ? "" : !UtilityFunctions.validatePassword(props.password)? "" : "is-invalid"}`} placeholder={props.pPlaceholder} value={props.password} onChange={(e) => { props.setpassword(e.target.value); props.setbtnClick(false); }} />
                <label>Password</label>
                <img onClick={togglePassword} src={UtilityFunctions.getImageURL() + `${passwordType === "password" ? !props.btnClick ? "eye_close.svg" : props.password.length > 0 ? "eye_close.svg" : "eye_close_red.svg" : (!props.btnClick ? "eye_open.svg" : (props.password.length > 0 ? "eye_open.svg" : "eye_open_red.svg"))}`} alt="" />
                {/* <i onClick={togglePassword} className={passwordType === "password" ? !props.btnClick ? "fa fa-eye-slash" : props.password.length > 0 ? "fa fa-eye-slash" : "fa fa-eye-slash red-eye-icon" : !props.btnClick ? "fa fa-eye" : props.password.length > 0 ? "fa fa-eye" : "fa fa-eye red-eye-icon"} aria-hidden="true"></i> */}
            </div>
            {!props.btnClick ? "" : !UtilityFunctions.validatePassword(props.password)? "" : <div className='display-error-message'><img alt="" className="toast-icon" src={UtilityFunctions.getImageURL() + "error-message-icon.svg"} /><span className='error-message-field'>{STRING_CONSTANTS.validationString.req_password}</span></div>}
        </div>
    )
}
export default SignInUp;
