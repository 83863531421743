import { getCurrentYear } from "../UtilityFunctions";

export const STRING_CONSTANTS = {
  // ## Form labels.
  formLabelString: {
    label_welcome: "Welcome Back!",
    label_logo: "LOGO",
    label_first_name: "First Name",
    label_last_name: "Last Name",
    label_email: "Email Address",
    label_number: "Phone Number",
    label_dateofbirth: "Date of Birth",
    label_gender: "Gender",
    label_male: "Male",
    label_female: "Female",
    label_other: "Other",
    label_password: "Password",
    label_confirm_password: "Confirm Password",
    label_login: "Login",
    label_logout: "logout",
    label_signup: "Sign up",
    resiter_your_self: "Register Your Self",
    label_select_option: "Select Item",
    label_toast_email: "Email",

    // Placeholders
    label_email_plc: "Enter your email.",
    label_password_plc: "Password",
    label_first_name_plc: "Enter your First Name.",
    label_last_name_plc: "Enter your Last Name.",
    label_number_plc: "Enter your Number.",
    label_confirm_password_plc: "Confirm Password",
    label_abn_number: "ABN Number",
    label_otp: "Otp",
    label_bussiness_address: "Bussiness Address",
    label_address_line_1: "Address Line 1",
    label_city: "City",
    label_profile: "Profile Image",
    label_field_req: "Required Fields",
    label_bussiness_logo: "Bussiness Logo",
    label_bussiness_name: "Business Name",
    label_subject: "Subject",
    label_message: "Message",
    label_exp_date: "Exp Date",
    label_card_holder_name: "Card Holder Name"
  },
  // ## Validation message strings.
  validationString: {
    req_email: "Please enter your email.",
    req_email_incorrect: "Please enter your Correct email.",
    req_password: "Please enter your Password.",
    req_first_name: "Please enter your First Name.",
    req_last_name: "Please enter your Last Name.",
    req_number: "Please enter your Number",
    req_number_incorrect: "Please enter your Correct Number.",
    req_dateofbirth: "Please enter your Date of Birth.",
    req_gender: "Please choose your Gender",
    req_confirm_password: "Please enter your Confirm Password.",
    req_abn_number: "Please enter your ABN number.",
    req_otp: "Please enter otp.",
    req_valid_otp: "Please enter valid otp.",
    req_bussiness_address: "Please enter Bussiness Address",
    req_address_line_1: "Please enter Address",
    req_city: "Please enter city",
    req_profile: "Please upload profile image",
    req_fields: "Please enter empty fields",
    req_bussiness_logo: "Please upload a bussiness logo",
    req_abn_number_inactive: "Please enter active ABN Number",
    req_bussiness_logo_size_exceeding:
      "Uploaded image size is greater then 2MB",
    req_password_incorrect:
      "Password should contain Min. 8 Characters, Min. 1 Number",
    req_password_notsame:
      "New password and confirm password not same. Try again.",
    req_bussiness_name: "Please enter Business Name",
    req_validate_password:
      "Password should contain minimum 8 char in which 1 char should be number",
    req_not_same_password:
      "Please re-check your new entered password its same with old password.Try a different one.",
    req_abn_number_incorrect: "Please enter valid ABN Number",
    req_subject: "Please enter subject",
    req_message: "Please enter message",
    req_exp_date: "Enter valid month",
    req_card_name: "Enter card holder name",
    req_card_no: "Enter card number",
    req_card_expiry: "Enter Exp Date",
    req_card_cvv: "Enter CVV",
    req_expDate : "Enter valid exp date"
  },
  // ## Routes String
  navigationString: {
    index: "/",
    test: "/test",
    signin: "/signin",
    signup: "/signup",
    forgotpwd: "/forgotPassword",
    resetpwd: "/resetPassword",
    mfa: "/mfa",
    profile: "/profile",
    Subscription: "/subscription",
    terms: "/terms",
    dashboard: "/dashboard",
    profileStep1: "/profileStep1",
    profileStep2: "/profileStep2",
    profileStep3: "/profileStep3",
  },
  // ## Cookie Names
  cookieStrings: {
    user: "user",
    token: "token",
    emailVerified: "emailVerified",
  },
  footerStrings: `Copyright © ${getCurrentYear()} Appboxer .All rights reserved.`,
  page404: {
    page_not_found: "Sorry, The page you're looking for not found.",
    back_to_home: "Back To Home",
  },
  pageProfile: {
    update_profile: "Update Profile",
  },
  // ## Configure Web Menus.
  menus: [
    { id: 0, name: "Home", link: "/" },
    { id: 1, name: "About", link: "/about" },
    { id: 2, name: "Services", link: "/services" },
    { id: 3, name: "Blog", link: "/blog" },
    { id: 4, name: "Contact", link: "/contact" },
  ],

  SOCIAL_TYPE: {
    FACEBOOK: "FaceBook",
    GOOGLE: "Google",
  },
  API_REQUEST: {
    GET: "GET",
    PATCH: "PATCH",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
  },
  API_HEADERS: {
    CONTENT_TYPE: "Content-Type",
    AUTHORIZATION: "Authorization",
    TYPE_FORM_DATA: "multipart/form-data",
    TYPE_RAW_DATA: "application/json",
    TYPE_MULTIPART_DATA: "multipart/form-data",
    TOKEN_PREFIX: "Bearer",
  },
  LOGIN_PAGE_STRING: {
    SIGNINEMAIL: "Your Email Address",
    SIGNINPASSWORD: "Your Password",
    SIGNUPEMAIL: "Add your Email Address",
    SIGNUPPASSWORD: "Let's get a Password",
  },
  API_ERRORS: {
    INTERNAL_SERVER_ERROR_HEADER: "Server error",
    INTERNAL_SERVER_ERROR: "500 Internal server error",
    DEACTIVE_USER: "Access error",
  },
  card_errors: {
    card: "Card",
    card_err: "Card field is required.",
    expiry: "Expiry",
    expiry_err: "Expiry field is required.",
    cvv: "CVV",
    cvv_err: "CVV field is required.",
    msg: "Message",
  },
};
