import React from "react";
import { toast } from 'react-toastify';
import { UtilityFunctions } from "../../src/utils/UtilityFunctions";

const ToastHeader = (err: any) => {

  return (
    toast(<div>
      <span className="toast-header error-toast">{err[0]}</span>
      
      {err[1]}
      <br/>
      <span className="toast-header error-toast">Tap to close</span>
    </div>, {
      icon: ({ theme, type }) => <img alt="" className="toast-icon" src={UtilityFunctions.getImageURL() + "error-toast-icon.svg"} />
    })

  )
}
export default ToastHeader;