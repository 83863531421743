/* eslint-disable no-throw-literal */
/* eslint-disable import/first */
import ES6Promise from 'es6-promise';
ES6Promise.polyfill();

import axios from 'axios';
import { cookieService , userService} from '../_services';
import { UtilityFunctions } from '../utils/UtilityFunctions';
import { STRING_CONSTANTS } from '../utils/constants/stringConstants';
import ValidationErrorToast from '../components/validationErrorToast';

let override = null;
const TIMEOUT_DURATION = 60000;
const BASE_URL = UtilityFunctions.API_URL();

// Create Axios instance with base paramaters
const instance = axios.create({
  baseURL: override !== null ? override : BASE_URL,
  timeout: TIMEOUT_DURATION,
  validateStatus: function (status: number) {
    return status >= 200 && status < 300; // default
  },
});

// Add a request interceptor
instance.interceptors.request.use(
  (config: any) => {
    // console.log("API Request Config:", config);

    const accessToken = cookieService.getCookie(STRING_CONSTANTS.cookieStrings.token);
    if (accessToken) {
      // config.headers.common.Authorization = null;
      config.headers[STRING_CONSTANTS.API_HEADERS.AUTHORIZATION] = `${STRING_CONSTANTS.API_HEADERS.TOKEN_PREFIX} ${accessToken}`
    }
    config.headers[STRING_CONSTANTS.API_HEADERS.CONTENT_TYPE] = STRING_CONSTANTS.API_HEADERS.TYPE_RAW_DATA;
    // config.headers[STRING_CONSTANTS.API_HEADERS.CONTENT_TYPE] =
    // STRING_CONSTANTS.API_HEADERS.TYPE_MULTIPART_DATA;
    // config.headers['Accept'] = 'application/json';
    //${STRING_CONSTANTS.API_HEADERS.TOKEN_PREFIX} 

    return config;
  },
  (error: any) => {
    // console.log("Request Error:", error);
    return Promise.reject(error);
  }

);

// Add a response interceptor
instance.interceptors.response.use(
  (response: any) => {
    // console.log("API Response", response.data)
    return response.data;
  },
  (error: any) => {
    // Do something with request error
    // console.log("Response Error:", errorMessageHandling(error));
    return Promise.reject(errorMessageHandling(error));
  });

const get = (url, params) => {
  return instance({
    url,
    method: STRING_CONSTANTS.API_REQUEST.GET,
    data: params,
  })
    .then(res => res.data)
}

const put = (url, params) => {
  return instance({
    url,
    method: STRING_CONSTANTS.API_REQUEST.PUT,
    data: params,
  })
}

const patch = (url, params) => {
  return instance({
    url,
    method: STRING_CONSTANTS.API_REQUEST.PATCH,
    data: params,
  })
}

const post = (url, params) => {
  return instance({
    url,
    method: STRING_CONSTANTS.API_REQUEST.POST,
    data: params,
  })
}

const deleteApi = (url, params) => {
  return instance({
    url,
    method: STRING_CONSTANTS.API_REQUEST.DELETE,
    data: params,
  })
}

const upload = (url, params) => {
  return instance({
    url,
    method: STRING_CONSTANTS.API_REQUEST.POST,
    data: params,
  })
}
export const api = { get, put, post, deleteApi, upload, patch };

const getErrors = (arr) => {
  // console.log('arr', arr);
  if(arr.code==="user_inactive"){
    userService.logout();
    cookieService.removeAllCookies();
    //useHistory().push('/signin');
    ValidationErrorToast(STRING_CONSTANTS.API_ERRORS.DEACTIVE_USER , arr.detail);
    //toast not getting visible -- whether page is getting refreshed or not in both cases toast will be visible through this implementation
    window.location.href = "/signin";
  }
  else{
    let op = [];
    Object.keys(arr).forEach(e => {
    //op = op + arr[e][0] + "\n";
    op[0] = e.charAt(0).toUpperCase() + e.slice(1);
    op[1] = arr[e][0];
    });
    return op;
  }
}
// Do not remove console, it used to format an error.
const ErrorFormatting = (error1) => {
  // console.log('error1', error1);
  return JSON.parse(JSON.stringify(error1.response))
}
const errorMessageHandling = (error) => {
  let errorObject = ErrorFormatting(error);
  // console.log('er', errorObject)
  //let store = "";
  let store = [];
  // if (errorObject && errorObject.data.errors != undefined) {
  //   store = errorObject.data.errors;
  //   console.log('Condition 1', store);
  // }
  // else if (errorObject && errorObject.data.result) {
  if (errorObject.status === 500) {
    store[0] = STRING_CONSTANTS.API_ERRORS.INTERNAL_SERVER_ERROR_HEADER;
    store[1] = STRING_CONSTANTS.API_ERRORS.INTERNAL_SERVER_ERROR;
  }
  else {
    store = getErrors(errorObject.data.errors);
  }

  //console.log('Condition 2', store);
  // }
  return store;
}