import React from "react";
import { toast } from 'react-toastify';
import { UtilityFunctions } from "../../src/utils/UtilityFunctions";

const SuccessToast = () => {

  return (
    toast.success(<div>
      <span className="toast-header">Success</span>
    </div>, {
      icon: ({ theme, type }) => <img alt="" className="toast-icon" src={UtilityFunctions.getImageURL() + "success-toast-icon.svg"} />
    })

  )
}
export default SuccessToast;