import React from 'react';
import { UtilityFunctions } from '../../utils/UtilityFunctions';

const Welcome = () => {
    return (
        <div className="col-sm-6">
            <div className="meditation">
                <img src={UtilityFunctions.getImageURL() + 'meditation.svg'} alt="meditation" />
                <div className="content-wrap">
                    <h4>Welcome to MindGeni</h4>
                    <p>We’re almost there...<br />
                        Lets get some details for your account.</p>
                </div>

            </div>
        </div>
    )
}
export default Welcome
