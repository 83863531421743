// "use strict";
import cookie from 'react-cookies';
import { STRING_CONSTANTS } from '../utils/constants/stringConstants'

export const cookieService = {
    saveCookie,
    getCookie,
    removeAllCookies,
};


function saveCookie(cookieName: string, value: any, options = null) {
    const cookieOptions = options ? options : {
        path: '/',
        sameSite: true,
        // secure: true // If we enable secure true then site must runs on https. otherwise it didn't work with http request.
    };
    cookie.save(cookieName, value, cookieOptions);
}

function getCookie(cookieName: string) {
    const value = cookie.load(cookieName)
    return value ? value : null
}

function removeAllCookies(options = null) {
    const cookieOptions = options ? options : {
        path: '/',
        sameSite: true,
        secure: true
    };
    const cookieItems = [STRING_CONSTANTS.cookieStrings.user, STRING_CONSTANTS.cookieStrings.token, STRING_CONSTANTS.cookieStrings.emailVerified];

    for (let i = 0; i < cookieItems.length; i++) {
        const cookieName = cookieItems[i] + '';
        cookie.remove(cookieName, cookieOptions);
    }
    return true;
}