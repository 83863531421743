import { api } from '../middleware/api';
import { cookieService } from './cookie.service';
// import { toast } from 'react-toastify';
import { STRING_CONSTANTS } from '../utils/constants/stringConstants';
import { END_POINTS } from '../utils/constants/endPoints';

export const userService = {
    login,
    logout,
    signup,
    updateProfile,
    forgotPassword,
    termsAndCondition,
    privacyAndPolicy,
    legalContent,
    faqContent,
    verifyOtp,
    sendOtp,
    abnNumberLookUp,
    viewProfile,
    changePwd,
    getProducts,
    clinicRegister,
    // stripePaymentIntent,
    stripeSetupintent,
    resetPassword,
    sendMessage,
    stripesubscription,
    checkPwd,
    cardsList,
    editCard,
    verifyEmail,
    selectedPlan,
    removeSavedCard,
    updateCard,
    clinicRegisterPatch
};

function login(params) {
    return api.post(END_POINTS.login, params);
}

function signup(params) {
    return api.post(END_POINTS.clinicalRegistration, params)
}

function updateProfile(params) {
    return api.patch(END_POINTS.profile, params);
}

function logout() {
    return api.post(END_POINTS.logout, null);
}

function forgotPassword(params) {
    return api.post(END_POINTS.forgotPassword, params);
}

function termsAndCondition() {
    return api.get(END_POINTS.termsAndCondition, null);
}

function privacyAndPolicy() {
    return api.get(END_POINTS.privacyAndPolicy, null);
}

function legalContent() {
    return api.get(END_POINTS.legalContent, null);
}
function faqContent() {
    return api.get(END_POINTS.faq, null);
}
function verifyOtp(params) {
    return api.post(END_POINTS.verifyOtp, params);
}
function sendOtp(params) {
    return api.post(END_POINTS.sendOtp, params);
}
function abnNumberLookUp(params) {
    return api.post(END_POINTS.abnNumber, params);
}
function viewProfile() {
    return api.get(END_POINTS.viewProfile, null);
}
function changePwd(params) {
    return api.post(END_POINTS.changePwd, params)
}
function getProducts() {
    return api.post(END_POINTS.stripePrice, null);
}
function clinicRegister(params) {
    return api.post(END_POINTS.clinicRegister, params);
}
// function stripePaymentIntent(data) {
//     return api.post(END_POINTS.stripeIntent, data);
// }
function stripeSetupintent() {
    return api.post(END_POINTS.stripesetupIntent, null);
}
function resetPassword(url, data) {
    return api.post(END_POINTS.resetPassword + url.uid + '/' + url.token + '/', data);
}
function sendMessage(data) {
    return api.post(END_POINTS.sendMessage, data);
}

// https://dev-api.mindgeni.com.au/api/main/stripe/setupintent/
function stripesubscription(data) {
    return api.post(END_POINTS.stripesubscription, data);
}
function checkPwd(data){
    return api.post(END_POINTS.checkPwd, data);
}
function cardsList(){
    return api.post(END_POINTS.cardsList, null);
}
function editCard(data){
    return api.post(END_POINTS.editCard, data);
}
function verifyEmail(data){
    return api.post(END_POINTS.verifyEmail, data);
}
function selectedPlan(){
    return api.post(END_POINTS.selectedPlan, null);
}
function removeSavedCard(param){
    return api.post(END_POINTS.deleteSavedCard, param);
}
function updateCard(param){
    return api.post(END_POINTS.updateCard, param);
}
function clinicRegisterPatch(params) {
    return api.patch(END_POINTS.clinicRegister, params);
}