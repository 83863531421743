/**
 * Not keeping as ENUM, as sometimes urls need to be structured dynamically
 * And this is not supported by ENUMs
 */
// API End Points.
export const END_POINTS = {
    login: "login/",
    clinicalRegistration: "clinician/register/",
    profile: "user/update/",
    forgotPassword: "forgot-password/",
    termsAndCondition: "tnc/",
    privacyAndPolicy: "pp/",
    legalContent: "tnc/",
    logout: "logout/",
    faq: "faq/",
    verifyOtp: "verifyotp/",
    sendOtp: "sendotp/",
    abnNumber: "abn/lookup/",
    stripeCustomer: "stripe/customer/",
    stripeProducts: "stripe/products/",
    stripePrice: "stripe/price/",
    stripePayment: "stripe/payment/",
    viewProfile: "user/profile/",
    changePwd: "change-password/",
    clinicRegister: "register/clinic/",
    stripeIntent: "stripe/paymentintent/",
    stripesetupIntent: "stripe/setupintent/",
    resetPassword: "user-password-reset-confirm/",
    sendMessage: "queryadmin/",
    stripesubscription: "stripe/subscription/",
    checkPwd: "check-password/",
    cardsList: "stripe/cards/",
    editCard: "stripe/edit-cards/",
    verifyEmail: "verify-email/",
    selectedPlan: "stripe/customer-subscription/",
    deleteSavedCard: "stripe/delete-cards/",
    updateCard: "stripe/update-subscription/"
};