import React, { useEffect, useState } from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import { UtilityFunctions } from 'utils/UtilityFunctions';
import { STRING_CONSTANTS } from 'utils/constants/stringConstants';
import ToastHeader from 'components/toastHeader';
import SuccessToast from 'components/successToast';
import ValidationErrorToast from 'components/validationErrorToast';
import { userService } from '_services';
import Welcome from './profile/welcome';

const Step3 = (props) => {
    const [code, setCode] = useState(props && props.location && props.location.state && props.location.state.code || "")
    const [clinicName, setClinicName] = useState(props && props.location && props.location.state && props.location.state.clinicName || "")
    const [addState, setAddState] = useState(props && props.location && props.location.state && props.location.state.addState || "")
    const [businessName, setBusinessName] = useState(props && props.location && props.location.state && props.location.state.businessName || "")
    const [abnNumber,setAbnNumber] = useState(props && props.location && props.location.state && props.location.state.abnNumber || "")
    const [clinicLogo,setClinicLogo] = useState(props && props.location && props.location.state && props.location.state.logo || "")
    const [btnClick, setbtnClick] = useState(false);
    const [bussinessAddress, setBussinessAddress] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [progress, setProgress] = useState(100);
    const [enableButton3, setEnableButton3] = useState(true);
    const [clinicId, setClinicId] = useState('');

    const updateStep3 = () => {
        setbtnClick(true);
        if (UtilityFunctions.isEmptyOrNullOrUndefined(businessName)) {
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_bussiness_name, STRING_CONSTANTS.validationString.req_bussiness_name);
        }
        else if (UtilityFunctions.isEmptyOrNullOrUndefined(bussinessAddress)) {
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_bussiness_address, STRING_CONSTANTS.validationString.req_bussiness_address);
        }
        else if (UtilityFunctions.isEmptyOrNullOrUndefined(address)) {
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_address_line_1, STRING_CONSTANTS.validationString.req_address_line_1);
        }
        else if (UtilityFunctions.isEmptyOrNullOrUndefined(city)) {
            ValidationErrorToast(STRING_CONSTANTS.formLabelString.label_city, STRING_CONSTANTS.validationString.req_city);
        }
        else {
            // const clinicParams = {
            //     clinic_name: clinicName,
            //     clinic_abn: abnNumber
            //     c
            // }
            let e = new FormData();
              e.append("clinic_name",clinicName);
              e.append("clinic_abn",abnNumber);
              e.append("clinic_logo", clinicLogo);
            if (bussinessAddress !== '' && address !== '' && city !== '' && enableButton3) {
                setEnableButton3(false);
                // userService.clinicRegister(e)
                //     .then((response) => {
                //         setClinicId(response.data.data.id);
                        userService.updateProfile({
                            clinic_address: bussinessAddress,
                            clinic_address_line_one: address,
                            clinic_city: city,
                            clinic_state: addState,
                            clinic_post_code: code,
                            //clinic: response.data.data.id,
                            clinic_name: businessName,
                            web_step: 3,
                            clinic_abn: abnNumber
                        })
                            .then((response) => {
                                setEnableButton3(true);
                                SuccessToast();
                                props.history.push('/settings/account');
                                return
                            })
                            .catch((err) => {
                                setEnableButton3(true);
                                ToastHeader(err);
                            })

                    // })
                    // .catch((err) => {
                    //     setEnableButton3(true);
                    //     ToastHeader(err);
                    // })
            }

        }
    }
    

    return (
        <React.Fragment>
            <div className='container-fluid p-0'>
                <div className="row gx-0 bg-blue vh-100">
                    <div className="col-sm-6 left-panel">
                        <div className="logo-wrap">
                            <img src={UtilityFunctions.getImageURL() + 'logo.svg'} alt="" />
                        </div>
        <div className="left-wrap w-100 outerspace">
            <h4 className='m-0'>Create Profile</h4>

            <div className='progress-wrap'> 
            <ProgressBar
                baseBgColor="rgb(255 255 255 / 12%)"
                bgColor="#FFF"
                height="4px"
                width="160px"
                margin="16px 0px 24px 0px"
                completed={100}
                customLabel="&nbsp;"
            />
                <span className='purple'>Almost there!...</span>
            </div>

            <h2 className='heading-wrap'>Lets Create your Profile.</h2>
            <h5 className='heading-wrap mb-4'>Some Business Details...</h5>

            <div className="row g-2">
                <div className='col-12'>
                    <div 
                        // className="form-floating-custom"
                         className={`form-floating-custom ${!btnClick ? "" : businessName.length > 0 ? "" : "red-bg"}`}
                        >
                        <input type="text" placeholder="Enter Business Name"
                            value={businessName} onChange={(e) => { setBusinessName(e.target.value); setbtnClick(false) }}
                            className={`form-floating-custom ${!btnClick ? "" : businessName.length > 0 ? "" : "is-invalid"}`}
                        />
                        <label>Business Name</label>
                    </div>
                </div>
                {!btnClick ? "" : businessName.length > 0 ? "" : <div className='display-error-message'><img alt="" className="toast-icon" src={UtilityFunctions.getImageURL() + "error-message-icon.svg"} /><span className='error-message-field'>{STRING_CONSTANTS.validationString.req_bussiness_address}</span></div>}
                <div className='mb-3'></div>
                <div className='col-12'>
                    <div 
                    // className="form-floating-custom"
                    className={`form-floating-custom ${!btnClick ? "" : bussinessAddress.length > 0 ? "" : "red-bg"}`}
                    
                    >
                        <input type="text" placeholder="Enter Business Address"
                            value={bussinessAddress} onChange={(e) => { setBussinessAddress(e.target.value); setbtnClick(false) }}
                            className={`form-floating-custom ${!btnClick ? "" : bussinessAddress.length > 0 ? "" : "is-invalid"}`}
                        />
                        <label>Business Address</label>
                    </div>
                </div>
                {!btnClick ? "" : bussinessAddress.length > 0 ? "" : <div className='display-error-message'><img alt="" className="toast-icon" src={UtilityFunctions.getImageURL() + "error-message-icon.svg"} /><span className='error-message-field'>{STRING_CONSTANTS.validationString.req_bussiness_address}</span></div>}
                <div className='mb-3'></div>
                <div className="col-md mb-m-20">
                    <div 
                    // className="form-floating-custom"
                    className={`form-floating-custom ${!btnClick ? "" : address.length > 0 ? "" : "red-bg"}`}
                    
                    >
                        <input type="text" placeholder="Add Address"
                            value={address} onChange={(e) => { setAddress(e.target.value); setbtnClick(false) }}
                            className={`form-floating-custom ${!btnClick ? "" : address.length > 0 ? "" : "is-invalid"}`}
                        />
                        <label>Address Line 1</label>
                    </div>
                    {!btnClick ? "" : address.length > 0 ? "" : <div className='display-error-message'><img alt="" className="toast-icon" src={UtilityFunctions.getImageURL() + "error-message-icon.svg"} /><span className='error-message-field'>{STRING_CONSTANTS.validationString.req_address_line_1}</span></div>}
                </div>
                <div className="col-md">
                    <div 
                        // className="form-floating-custom"
                        className={`form-floating-custom ${!btnClick ? "" : city.length > 0 ? "" : "red-bg"}`}
                        
                        >
                        <input type="text" placeholder="Enter City & Suburb"
                            value={city} onChange={(e) => { setCity(e.target.value); setbtnClick(false) }}
                            className={`form-floating-custom ${!btnClick ? "" : city.length > 0 ? "" : "is-invalid"}`}
                        />
                        <label>City/Suburb</label>
                    </div>
                    {!btnClick ? "" : city.length > 0 ? "" : <div className='display-error-message'><img alt="" className="toast-icon" src={UtilityFunctions.getImageURL() + "error-message-icon.svg"} /><span className='error-message-field'>{STRING_CONSTANTS.validationString.req_city}</span></div>}
                </div>

                <div className='mb-3'></div>
                <div className="col-md mb-m-10">
                    <div className="form-floating-custom mb-3">
                        <input type="text" className="" placeholder="State" value={addState} disabled={true} />
                        <label>Enter State</label>
                    </div>
                </div>
                <div className="col-md">
                    <div className="form-floating-custom">
                        <input type="text" placeholder="Add Post Code" value={code} disabled={true} />
                        <label>Post Code</label>
                    </div>
                </div>
            </div>

            {/* <div className="d-grid text-center mt-5">
                <button className='btn btn-cta'> Next Step</button>
            </div> */}
        </div>
        <div className="d-grid text-center w-100">
                            {<button className='btn btn-cta  mt-4' onClick={() => updateStep3()}>Next Step</button>}
                        </div>
        </div>
        <Welcome/>
        </div>
        </div>
        </React.Fragment>
    )
}
export default Step3;
